import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { useReactToPrint } from "react-to-print"

import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import { formatDateBackend, formatDate, formatMoney, handleFocus } from 'helpers/helpers'
import { GET } from 'helpers/api'


function Balance({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()

	const printRef = useRef(null);

	const [filterData, setFilterData] = useState({
		start_date: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		end_date: formatDateBackend(new Date()),
	})

	const [data, setData] = useState({
		fuels: [],
		total: {},
	})

	const handlePrint = useReactToPrint({
		content: () => printRef.current
	});

	async function getData() {
		const response = await GET('/balance', filterData, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width custom-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center gap-3 w-100">
						<h4>{t('BALANCE2')}</h4>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="px-5" ref={printRef}>

						<div className="text-center fz-20 mb-3">
							<div>
								<div>{reduxAccount?.pos_name}</div>
								Йигма кайднома (Сводная ведомость)
								<div className="d-flex justify-content-center">
									(c) {formatDate(filterData.start_date)}
									<div className="mx-2"></div>
									(по) {formatDate(filterData.end_date)}
								</div>
								<div>
									{formatDate(new Date())}
								</div>
							</div>
						</div>

						<div>
							<table className="table balance-table">
								<thead>
									<tr>
										<th rowSpan="2" style={{ width: '10%' }}>{t('POS')}</th>
										<th rowSpan="2" style={{ width: '3%' }}>{t('FUEL')}</th>
										<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Сальдо на: {formatDate(filterData.start_date)} га сальдо  </th>
										<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Кирим (приход)</th>
										<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Чиким (Расход)</th>
										<th colSpan="3" style={{ width: '20%' }} className="after-101 text-center" scope="colgroup">Сальдо на: {formatDate(filterData.end_date)} га сальдо  </th>
										<th rowSpan="2" style={{ width: '3.3%' }}>Махсулот айланмаси <br /> (Товарооборот)</th>
										<th rowSpan="2" style={{ width: '3.3%' }}>Жами даромад <br /> (Валовый доход)</th>
									</tr>
									<tr>
										<th>Литр</th>
										<th>Кг</th>
										<th>Сумма</th>

										<th>Литр</th>
										<th>Кг</th>
										<th>Сумма</th>

										<th>Литр</th>
										<th>Кг</th>
										<th>Сумма</th>


										<th>Литр</th>
										<th>Кг</th>
										<th>Сумма</th>
									</tr>
								</thead>
								<tbody>
									{data.fuels?.map((item, index) => (
										<tr key={index} className="tr-nowrap">
											<td className="text-start">{reduxAccount.pos_name}</td>
											<td className="text-start">{item.name}</td>

											<td>{formatMoney(item.start_prev_balance)}</td>
											<td>{formatMoney(Math.round(Number(item.start_prev_balance) * 0.735))}</td>
											<td>{formatMoney(Number(item.start_prev_balance) * Number(item.start_price))}</td>

											<td>{formatMoney(item.total_mass_liter)}</td>
											<td>{formatMoney(Math.round(Number(item.total_mass_kg)))}</td>
											<td>{formatMoney(item.total_mass_amount)}</td>

											<td>{formatMoney(item.total_sold)}</td>
											<td>{formatMoney(Math.round(Number(item.total_sold) * 0.735))}</td>
											<td>{formatMoney(item.total_amount)}</td>

											<td>{formatMoney(item.end_next_balance)}</td>
											<td>{formatMoney(Math.round(Number(item.end_next_balance) * 0.735))}</td>
											<td>{formatMoney(Number(item.end_next_balance) * Number(item.end_price))}</td>

											<td>{formatMoney(item.turnover)}</td>
											<td>{formatMoney((item.total_amount + item.total_mass_amount) - (Number(item.start_prev_balance) * Number(item.start_price) - Number(item.end_next_balance) * Number(item.end_price)))}</td>
										</tr>
									))}
									<tr className="tr-bold tr-nowrap">
										<td className="text-start" colSpan={2}>{t('TOTAL')}: </td>

										<td>{formatMoney(data.total.start_prev_balance)}</td>
										<td>{formatMoney(Math.round(Number(data.total.start_prev_balance) * 0.735))}</td>
										<td>{formatMoney(data.total.start_amount)}</td>

										<td>{formatMoney(data.total.total_mass_liter)}</td>
										<td>{formatMoney(Math.round(Number(data.total.total_mass_kg)))}</td>
										<td>{formatMoney(data.total.total_mass_amount)}</td>

										<td>{formatMoney(data.total.total_sold)}</td>
										<td>{formatMoney(Math.round(Number(data.total.total_sold) * 0.735))}</td>
										<td>{formatMoney(data.total.total_amount)}</td>

										<td>{formatMoney(data.total.end_next_balance)}</td>
										<td>{formatMoney(Math.round(Number(data.total.end_next_balance) * 0.735))}</td>
										<td>{formatMoney(data.total.end_amount)}</td>

										<td>{formatMoney(data.total.turnover)}</td>
										<td>{formatMoney((data.total.total_amount + data.total.total_mass_amount) - (data.total.start_amount + data.total.end_amount))}</td>
									</tr>
								</tbody>
							</table>

							<div className="balance-card">
								<div className="row">
									<div className="col-md-8">
										Махсулот айланмаси (товарооборот)
									</div>
									<div className="col-md-4 text-center">
										{formatMoney(data.total.turnover)}
									</div>
								</div>
							</div>

							<div className="balance-card">
								<div className="row">
									<div className="col-md-8">
										Жами даромад (Валовый доход)
									</div>
									<div className="col-md-4 text-center">
										{formatMoney((data.total.total_amount + data.total.total_mass_amount) - (data.total.start_amount + data.total.end_amount))}
									</div>
								</div>
							</div>

							<div className="balance-card">
								<div className="row">
									<div className="col-md-8">
										Солик (Налог)
									</div>
									<div className="col-md-4 text-center">
										{formatMoney(data.total.total_tax)}
									</div>
								</div>
							</div>

							<div className="balance-card">
								<div className="row">
									<div className="col-md-8">
										Сумма без налога
									</div>
									<div className="col-md-4 text-center">
										{formatMoney(Number(data.total.turnover) - Number(data.total.total_tax))}
									</div>
								</div>
							</div>

						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<div className="">
						<label className="mb-0 vertical-center" htmlFor="">{t('PERIOD')}</label>

						<div className="d-flex gap-3">
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={filterData.start_date ? new Date(filterData.start_date) : ''}
									onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })}
									onFocus={(e) => handleFocus(e)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>

							<div className="vertical-center">
								-
							</div>

							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={filterData.end_date ? new Date(filterData.end_date) : ''}
									onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })}
									onFocus={(e) => handleFocus(e)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>

							<button className="btn btn-outline-primary" onClick={() => getData()}>
								Сформировать
							</button>
						</div>
					</div>

					<div className="d-flex gap-3">
						<button className="btn btn-primary" style={{ width: '120px' }} onClick={() => handlePrint()}>
							<i className="uil-print fz-20"></i>
							<div>
								{t('PRINT')}
							</div>
						</button>

						<button className="btn btn-danger" style={{ width: '120px' }} onClick={() => setShowModal(false)}>
							<i className="uil-file-times fz-20"></i>
							<div>
								{t('CLOSE')}
							</div>
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Balance