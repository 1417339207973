const initialState = {
	loss: 0.088,
	rounding: 'round',
	vat: 12,
	payme: {},
}

const settings = function (state = initialState, action) {
	switch (action.type) {
		case "SET_SETTINGS":
			return action.payload;
		default:
			return state;
	}
};

export default settings