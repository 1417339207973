export let exportMenu = [
	{
		icon: "uil-setting sidebar-icon",
		children: [
			{
				title: true,
				i18n: "HOME",
			},
			{
				url: "/admin/users",
				i18n: "USERS",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/admin/poses",
				i18n: "POS",
				icon: "uil-shop",
				active: false,
			},
			{
				url: "/admin/director",
				i18n: "DIRECTOR",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/admin/accountant",
				i18n: "accountant",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/admin/cashier",
				i18n: "CASHIER",
				icon: "uil-users-alt",
				active: false,
			},
		]
	},
]