import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { useSelector } from 'react-redux'

import BalancePrint from 'pages/components/BalanceReport'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import { GET } from 'helpers/api'
import { formatDateBackend, handleFocus } from 'helpers/helpers'

function DailyReport() {
	const { t } = useTranslation()

	const printRef = useRef(null)

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()

	const [filterData, setFilterData] = useState({
		pos_id: null,
		start_date: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		end_date: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)),
	})

	const [poses, setPoses] = useState([])


	const [data, setData] = useState({
		fuels: [],
		total: {},
	})

	const handlePrint = useReactToPrint({
		content: () => printRef.current
	});

	async function getPoses() {
		const response = await GET('/pos-helper', { login: reduxAccount.parent },)
		if (response) {
			setPoses(response)
			setFilterData({ ...filterData, pos_id: response[0]?.id })
		}
	}

	async function getData() {
		const response = await GET('/balance', filterData, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getPoses()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex align-items-center gap-3">
					<h4>
						{t('SHIFT_REPORT')}
					</h4>

					<div style={{ width: '250px' }}>
						<Select
							options={poses}
							value={poses.find(option => option.id === filterData.pos_id)}
							onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>

					<div className="calendar-input">
						<DatePicker
							className="form-control"
							dateFormat="dd.MM.yyyy"
							selected={filterData.start_date ? new Date(filterData.start_date) : ''}
							onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })}
							onFocus={(e) => handleFocus(e)}
							maxDate={new Date()}
						/>
						<i className="uil uil-calendar-alt"></i>
					</div>

					<div className="vertical-center">
						-
					</div>

					<div className="calendar-input">
						<DatePicker
							className="form-control"
							dateFormat="dd.MM.yyyy"
							selected={filterData.end_date ? new Date(filterData.end_date) : ''}
							onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })}
							onFocus={(e) => handleFocus(e)}
							maxDate={new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)}
						/>
						<i className="uil uil-calendar-alt"></i>
					</div>

					<button className="btn btn-outline-primary" onClick={() => getData()}>
						Сформировать
					</button>

				</div>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => handlePrint()}>
						<i className="uil uil-print me-2"></i>
						{t('PRINT')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					{data?.fuels?.length > 0 &&
						<BalancePrint data={data} filterData={filterData} printRef={printRef} />
					}
				</div>
			</div>
		</>
	)
}

export default DailyReport