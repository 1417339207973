import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import ru from "./locales/ru.json"
import uzCyrl from "./locales/uzCyrl.json"
import uzLatn from "./locales/uzLatn.json"

// the translations
const resources = {
	"ru": {
    translation: ru,
  },
	"uz_cyrl": {
    translation: uzCyrl,
  },
	"uz_latn": {
    translation: uzLatn,
  },
}

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "ru")
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "ru",
    fallbackLng: "ru", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
