import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { GET, PUT } from 'helpers/api'

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'address': '',
		'phone': '',
	})

	async function createUpdate() {
		var selectedItemCopy = { ...selectedItem }
		await PUT('/pos', selectedItemCopy)
		getData()
	}

	// function handleChangeActivated(e, index) {
	// 	dataCopy.data[index][e.target.name] = e.target.checked
	// }

	async function getData() {
		const response = await GET('/pos', {}, { loader: true })
		if (response) {
			setSelectedItem(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('POS')}</h4>
						<button className="btn-rounded btn btn-primary me-2" onClick={() => createUpdate()}>
							{t('SAVE')}
						</button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="d-flex">
								<div className="form-group me-2">
									<label>{t('NAME')}</label>
									<input type="text" className="form-control" name="name" value={selectedItem.name}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
								</div>
								<div className="form-group me-2">
									<label>{t('ADDRESS')}</label>
									<input type="text" className="form-control" name="address" value={selectedItem.address}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'address': e.target.value })} />
								</div>
								<div className="form-group me-2">
									<label>{t('TIN')}</label>
									<input type="number" className="form-control" name="tin" value={selectedItem.tin}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'tin': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('PHONE')}</label>
									<input type="text" className="form-control" name="phone" value={selectedItem.phone}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Index