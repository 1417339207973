import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom'

import FuelInModal from 'pages/director/modals/FuelIn';
import PosModal from 'pages/director/modals/Pos';
import CarpoolModal from 'pages/director/modals/Carpool';
import CarModal from 'pages/director/modals/Car';
import OilStorageModal from 'pages/director/modals/OilStorage';
import FuelModal from 'pages/director/modals/Fuel';
import ProxyModal from 'pages/director/modals/Proxy';
import StorageTankModal from 'pages/director/modals/StorageTank';
import FuelDispenserModal from 'pages/director/modals/FuelDispenser';
import PipelineModal from 'pages/director/modals/Pipeline';
import PaymentModal from 'pages/director/modals/Payment';
import CashierModal from 'pages/director/modals/Cashier';
import SettingModal from 'pages/director/modals/Setting';
import OperatorModal from 'pages/director/modals/Operator';

import ReportFuelInModal from 'pages/director/report/FuelIn';
import ReportInventorizationModal from 'pages/director/report/Inventorization';
import ReportActionHistoryModal from 'pages/director/report/ActionHistory';
import ReportBalanceModal from 'pages/director/report/Balance';

import LanguageDropdown from 'containers/LanguageDropdown';

import { POST } from 'helpers/api';
import { Dropdown } from 'react-bootstrap';

export default function Navbar() {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const reduxReportData = useSelector(state => state.report)

	const reportDropdownRef = useRef(null);
	const handbookDropdownRef = useRef(null);

	useOutsideAlerter(reportDropdownRef, 1);
	useOutsideAlerter(handbookDropdownRef, 2);

	const [handbookDropdown, setHandbookDropdown] = useState(false)
	const [reportDropdown, setReportDropdown] = useState(false)
	const [showFuelInModal, setShowFuelInModal] = useState(false)
	const [showPosModal, setShowPosModal] = useState(false)
	const [showCarpoolModal, setShowCarpoolModal] = useState(false)
	const [showOilStorageModal, setShowOilStorageModal] = useState(false)
	const [showFuelModal, setShowFuelModal] = useState(false)
	const [showCarModal, setShowCarModal] = useState(false)
	const [showProxyModal, setShowProxyModal] = useState(false)
	const [showTankModal, setShowTankModal] = useState(false)
	const [showDispenserModal, setShowDispenserModal] = useState(false)
	const [showOperartorModal, setShowOperartorModal] = useState(false)
	const [showPipelineModal, setShowPipelineModal] = useState(false)
	const [showPaymentModal, setShowPaymentModal] = useState(false)
	const [showCashierModal, setShowCashierModal] = useState(false)

	const [showSettingsModal, setShowSettingsModal] = useState(false)
	const [showReportFuelInModal, setShowReportFuelInModal] = useState(false)
	const [showInventorization, setShowInventorization] = useState(false)
	const [showLogs, setShowLogs] = useState(false)
	const [showBalance, setShowBalance] = useState(false)

	function exit() {
		localStorage.clear()
		navigate('/auth/login')
	}

	async function updateCurrentShift() {
		var sendData = { ...reduxReportData.reportData }
		await POST('/update-current-shift', sendData, { loader: false, toast: true })
		// dispatch(UPDATE_SHIFT((reduxReportData.updateShift ?? 0) + 1))
		window.location.reload()
	}

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document?.documentElement?.requestFullscreen) {
				document?.documentElement?.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function useOutsideAlerter(ref, num) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					if (num === 1) {
						setReportDropdown(false)
					} else if (num === 2) {
						setHandbookDropdown(false)
					}
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<header className='page-topbar'>
				<div className='navbar-header'>
					<div className='d-flex'>
						{/* <button className='btn btn-primary me-2 btn-sm'>
							Смена
						</button> */}
						<button className='btn btn-primary me-2 btn-sm' onClick={() => setShowFuelInModal(true)}>
							{t('FUEL_IN')}
						</button>
						<div className="dropdown" ref={reportDropdownRef} onClick={() => setReportDropdown(true)}>
							<span className="btn btn-primary btn-sm dropdown-toggle me-2"
								role="button" id="dropdownMenuLink"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{t('REPORTS')}
							</span>
							<div className={`dropdown-menu cursor__span ${reportDropdown ? 'show' : false}`}
								aria-labelledby="dropdownMenuLink" >
								<Link to='/shift-report'>
									<span className="dropdown-item">{t('shift_report')}</span>
								</Link>
								<div className="dropdown-divider"></div>
								<span className="dropdown-item" onClick={() => setShowReportFuelInModal(true)}>{t('FUEL_IN')}</span>
								<span className="dropdown-item" onClick={() => setShowBalance(true)}>Сальдо</span>
								<span className="dropdown-item" onClick={() => setShowInventorization(true)}>{t('inventory')}</span>
								<span className="dropdown-item" onClick={() => setShowLogs(true)}>{t('action_history')}</span>
							</div>
						</div>
						<div className="dropdown" onClick={() => setHandbookDropdown(true)} ref={handbookDropdownRef}>
							<span className="btn btn-primary btn-sm dropdown-toggle me-2" role="button" id="dropdownMenuLink"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{t('directory')}
							</span>
							<div className={`dropdown-menu cursor__span ${handbookDropdown ? 'show' : false}`}
								aria-labelledby="dropdownMenuLink">
								<span className="dropdown-item" onClick={() => setShowPosModal(true)}>{t('POS')}</span>
								<span className="dropdown-item" onClick={() => setShowCarpoolModal(true)}>{t('CARPOOL')}</span>
								<span className="dropdown-item" onClick={() => setShowOilStorageModal(true)}>{t('OIL_STORAGE')}</span>
								<span className="dropdown-item" onClick={() => setShowFuelModal(true)}>{t('FUEL')}</span>
								<span className="dropdown-item" onClick={() => setShowCarModal(true)}>{t('CARS')}</span>
								<span className="dropdown-item" onClick={() => setShowProxyModal(true)}>{t('PROXY')}</span>
								<span className="dropdown-item" onClick={() => setShowTankModal(true)}>{t('STORAGE_TANK')}</span>
								<span className="dropdown-item" onClick={() => setShowDispenserModal(true)}>{t('FUEL_DISPENSER')}</span>
								<span className="dropdown-item" onClick={() => setShowOperartorModal(true)}>{t('OPERATORS')}</span>
								<span className="dropdown-item" onClick={() => setShowPipelineModal(true)}>{t('PIPELINES')}</span>
								<span className="dropdown-item" onClick={() => setShowPaymentModal(true)}>{t('PAYMENTS')}</span>
								<span className="dropdown-item" onClick={() => setShowCashierModal(true)}>{t('CASHIERS')}</span>
								{/* <span className="dropdown-item" onClick={() => setShowDispenserModal(true)}>Естественная убыль</span> */}
							</div>
						</div>
						<button className='btn btn-primary me-2 btn-sm' onClick={() => setShowSettingsModal(true)}>
							{t('SETTINGS')}
						</button>
						<button className='btn btn-primary me-2 btn-sm' onClick={() => updateCurrentShift()}>
							<i className="uil uil-sync me-2"></i>
							{t('UPDATE')}
						</button>

					</div>
					<div className='d-flex align-items-center'>
						<Dropdown className="btn cursor-pointer cursor header-item">
							<Dropdown.Toggle as={LanguageToggle}>
								<i className="uil-desktop fz-20"></i>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item href="https://backend.betrim.uz/download/anydesk_32.exe" target="_blank" rel="noopener noreferrer">
									Anydesk - x32
								</Dropdown.Item>
								<Dropdown.Item href="https://backend.betrim.uz/download/anydesk_64.exe" target="_blank" rel="noopener noreferrer">
									Anydesk - x64
								</Dropdown.Item>
								<Dropdown.Item href="https://backend.betrim.uz/download/ammyy_admin.exe" target="_blank" rel="noopener noreferrer">
									Ammyy Admin
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

						<LanguageDropdown />

						<button type="button"
							onClick={() => toggleFullscreen()}
							className="btn header-item noti-icon waves-effect"
							data-toggle="fullscreen">
							<i className="uil-minus-path"></i>
						</button>

						<button className='btn btn-danger ms-3 btn-sm' onClick={exit}>{t('LOGOUT')}</button>
					</div>
				</div>
			</header>

			{/* Приход топлива */}
			<FuelInModal showModal={showFuelInModal} setShowModal={setShowFuelInModal} />
			{/* Настройки */}
			<SettingModal showModal={showSettingsModal} setShowModal={setShowSettingsModal} />
			{/* Автобаза */}
			<PosModal showModal={showPosModal} setShowModal={setShowPosModal} />
			{/* Автобаза */}
			<CarpoolModal showModal={showCarpoolModal} setShowModal={setShowCarpoolModal} />
			{/* Машины */}
			<CarModal showModal={showCarModal} setShowModal={setShowCarModal} />
			{/* Нефтебаза */}
			<OilStorageModal showModal={showOilStorageModal} setShowModal={setShowOilStorageModal} />
			{/* Толиво */}
			<FuelModal showModal={showFuelModal} setShowModal={setShowFuelModal} />
			{/* Доверенность */}
			<ProxyModal showModal={showProxyModal} setShowModal={setShowProxyModal} />
			{/* Резервуар */}
			<StorageTankModal showModal={showTankModal} setShowModal={setShowTankModal} />
			{/* ТРК (Топливораздаточная колонка (Пистолет))  */}
			<FuelDispenserModal showModal={showDispenserModal} setShowModal={setShowDispenserModal} />
			{/* Операторы  */}
			<OperatorModal showModal={showOperartorModal} setShowModal={setShowOperartorModal} />
			{/* Трубопровод  */}
			<PipelineModal showModal={showPipelineModal} setShowModal={setShowPipelineModal} />
			{/* Платежи  */}
			<PaymentModal showModal={showPaymentModal} setShowModal={setShowPaymentModal} />
			{/* Платежи  */}
			<CashierModal showModal={showCashierModal} setShowModal={setShowCashierModal} />

			{/*  */}
			<ReportFuelInModal showModal={showReportFuelInModal} setShowModal={setShowReportFuelInModal} />
			{/* Inventorization */}
			<ReportInventorizationModal showModal={showInventorization} setShowModal={setShowInventorization} />
			{/* Action History */}
			<ReportActionHistoryModal showModal={showLogs} setShowModal={setShowLogs} />
			{/* Balance */}
			<ReportBalanceModal showModal={showBalance} setShowModal={setShowBalance} />
		</>
	)
}