import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { formatDate, regexPhoneNumber } from 'helpers/helpers'
import { GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'parent': '',
		// 'pos_id': '',
		'login': '',
		'phone': '',
		'password': '',
	})

	const [data, setData] = useState([])
	const [users, setUsers] = useState([])
	// const [poses, setPoses] = useState([])

	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.phone = '998' + selectedItemCopy.phone
		if (!selectedItemCopy.id) {
			selectedItemCopy.role = "ROLE_ACCOUNTANT"
			await POST('/admin/users', selectedItemCopy)
		} else {
			await PUT('/admin/users', selectedItemCopy)
		}

		setSelectedItem({})
		setShowModal(false)
		getData()
	}

	function openModal(item) {
		var selectedItemCopy = { ...item }
		selectedItemCopy.phone = selectedItemCopy.phone.substring(3, 12)
		setSelectedItem(selectedItemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'parent': '',
			// 'pos_id': '',
			'login': '',
			'phone': '',
			'password': '',
		})
		setShowModal(false)
	}

	// async function getPoses() {
	// 	const response = await GET('/pos-helper', {login: selectedItem.parent})
	// 	if (response) {
	// 		setPoses(response)
	// 	}
	// }

	async function getUsers() {
		const response = await GET('/users-helper', { role: 'ROLE_OWNER' })
		if (response) {
			setUsers(response)
		}
	}

	async function getData() {
		const response = await GET('/admin/users', { role: 'ROLE_ACCOUNTANT' }, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
		getUsers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// useDidMountEffect(() => {
	// 	getPoses()
	// }, [selectedItem.parent]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('accountant')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('LOGIN')}</th>
									<th className="text-center">{t('PHONE')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.login}</td>
										<td className="text-center">{regexPhoneNumber(item.phone)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('CREATE')}</span>
							:
							<span>{t('EDIT')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('USER')}</label>
							<Select
								options={users}
								value={users.find(option => option.id === selectedItem.parent)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'parent': option.login })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.login}
								getOptionValue={(option) => option.login}
								isDisabled={selectedItem.id}
							/>
						</div>
						{/* <div className="form-group">
							<label>{t('POS')}</label>
							<Select
								options={poses}
								value={poses.find(option => option.id === selectedItem.pos_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'pos_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								isDisabled={selectedItem.id}
							/>
						</div> */}
						<div className="form-group">
							<label>{t('LOGIN')}</label>
							<input type="text" className="form-control" name="login" value={selectedItem.login}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'login': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('PHONE')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="text" className="form-control" name="phone" value={selectedItem.phone}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('PASSWORD')}</label>
							<input type="text" className="form-control" name="password" value={selectedItem.password}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index