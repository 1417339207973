import { getTime, format } from 'date-fns'
import { ru } from 'date-fns/esm/locale'
import i18n from '../i18n'

export function regexLogin(str) {
	if (str) {
		return str.toLowerCase().replace(/[^a-z0-9_]/gi, '');
	}
	return ""
}

export function deleteSymbols(str) {
	if (str) {
		return str.replace(/[^A-Z0-9]/gi, '');
	}
	return ""
}

export function regexNumeric(str) {
	if (str) {
		return str.replace(/[^0-9]/gi, '');
	}
	return ""
}

export function returnSign(str) {
	return str.includes("?") ? '&' : '?'
}

export function returnMonth(number) {
	const dates = [
		{ 'id': 1, 'name': i18n.t('JANUARY') },
		{ 'id': 2, 'name': i18n.t('FEBRUARY') },
		{ 'id': 3, 'name': i18n.t('MARCH') },
		{ 'id': 4, 'name': i18n.t('APRIL') },
		{ 'id': 5, 'name': i18n.t('MAY') },
		{ 'id': 6, 'name': i18n.t('JUNE') },
		{ 'id': 7, 'name': i18n.t('JULY') },
		{ 'id': 8, 'name': i18n.t('AUGUST') },
		{ 'id': 9, 'name': i18n.t('SEPTEMBER') },
		{ 'id': 10, 'name': i18n.t('OCTOBER') },
		{ 'id': 11, 'name': i18n.t('NOVEMBER') },
		{ 'id': 12, 'name': i18n.t('DECEMBER') },
	]
	if (number) {
		return dates.find(item => item.id === number)['name']
	} else {
		return ''
	}
}

export function regexPhoneNumber(str) {
	if (str) {
		return str.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "$1$2 $3-$4-$5");
	}
	return ""
}

export function clearTemporaryStorage() {
	localStorage.removeItem('login')
	localStorage.removeItem('role')
	localStorage.removeItem('token')
}

export function findKeyFromArrayByValue(array, valueName, value, key) {
	if ((array.length > 0 && value) || value === 0) {
		return array.filter(x => x[valueName] === value)[0][key];
	}
}

export function formatMoneyInput(amount) {
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = " ") {
	if (!amount) {
		return 0
	}

	if (Number.isInteger(Number(amount))) {
		decimalCount = 0
	} else {
		decimalCount = 2
	}

	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 0 : decimalCount; // 0 was 2

		const negativeSign = amount < 0 ? "-" : "";

		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	} catch (e) {
		console.log(e)
	}
}

export function formatMoneyWithCommas(x) {
	if (!x) {
		return x
	}
	// if (countDecimals(x) > 3) {
	// 	x.toString().substr(0, x.length - 1)
	// }
	// return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return x;
}

export function formatDateWithTime(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'dd.MM.yyyy HH:mm');
		return formattedDate
	} else {
		return '';
	}
}

export function countDecimals(value) {
	if (!value) return;
	Number(value)
	if (Math.floor(value) === value) return 0;
	return value?.toString()?.split(".")[1]?.length || 0;

}

export function formatDateCalendar(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'dd MMMM yyyy', { locale: ru });
		return formattedDate
	}
}

export function formatDate(date) {
	try {
		if (date) {
			var formattedDate = format(new Date(date), 'dd.MM.yyyy');
			return formattedDate
		}
	} catch (error) {
		console.log(error);
	}
}

export function formatTime(date, formatter = 'HH:mm') {
	if (date) {
		var formattedDate = format(new Date(date), formatter);
		return formattedDate
	}
}

export function formatDateBackend(date) {
	try {
		if (date) {
			var formattedDate = format(new Date(date), 'yyyy-MM-dd');
			return formattedDate
		}
	} catch (error) {
		console.log(error);
	}
}

export function formatDateTimeBackend(date) {
	if (date) {
		var formattedDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
		return formattedDate
	}
}

export function formatDateFromString(dateString) {
	const parts = dateString.split('.');
	const day = parseInt(parts[0], 10);
	const month = parseInt(parts[1], 10) - 1;
	const year = parseInt(parts[2], 10);
	const date = new Date(year, month, day);
	var formattedDate = format(new Date(date), 'yyyy-MM-dd');
	return formattedDate;
}


export function formatTimeFromString(timeString) {
	const hours = parseInt(timeString.substring(0, 2), 10);
	const minutes = parseInt(timeString.substring(2, 4), 10);
	const currentDate = new Date();
	currentDate.setHours(hours);
	currentDate.setMinutes(minutes);
	return currentDate;
}


export function hoursBetween(date1, date2) {
	var firstDate = new Date(date1)
	var secondDate = date2 ? new Date(date2) : new Date()
	return (Math.abs(firstDate - secondDate) / 36e5).toFixed(2);
}

export function getUnixTime() {
	return getTime(new Date())
}

export function todayDate() {
	return format(new Date(), 'dd.MM.yyyy HH:mm:ss')
}

export function todayDDMMYYYY() {
	return format(new Date(), 'dd.MM.yyyy')
}

export function getHHmm() {
	return format(new Date(), 'HH:mm')
}

export function getUnixTimeByDate(date) {
	return getTime(date)
}

export function formatUnixTime(unixTime) {
	if (unixTime)
		return format(new Date(unixTime), 'dd.MM.yyyy HH:mm:ss')
}

export function sumFromArray(array, key) {
	var sum = 0
	if (array && array.length > 0) {
		for (let i = 0; i < array.length; i++) {
			sum += Number(array[i][key]);
		}
	}

	return sum
}

export function returnMinDate(date, days) {
	var result = new Date(date);
	result.setDate(result.getDate() - days);
	return result;
}

export function countTotalFromArray(array = [], key) {
	var count = 0

	if (array?.length > 0) {
		for (let i = 0; i < array.length; i++) {
			count += Number(array[i][key])
		}
	}
	return count
}

export function generateTransactionId(posId, userId) {
	if (posId && userId)
		return posId.toString() + userId.toString() + getUnixTime().toString() + Math.floor(Math.random() * 999999)
}

export function onlyNumbers(event) {
	if (!/[0-9]/.test(event.key)) {
		event.preventDefault();
	}
}

export function numberToWords(number) {
	var ones = ['', 'bir', 'ikki', 'uch', 'to\'rt', 'besh', 'olti', 'yetti', 'sakkiz', 'to\'qqiz'];
	var tens = ['', 'o\'n', 'yigirma', 'ottiz', 'qirq', 'ellik', 'oltmish', 'yetmish', 'sakson', 'to\'qson'];

	if (localStorage.getItem('uz_cyrl')) {
		ones = ['', 'бир', 'икки', 'уч', 'тўрт', 'беш', 'олти', 'етти', 'саккиз', 'тўққиз'];
		tens = ['', 'ўн', 'йигирма', 'оттиз', 'қирқ', 'эллик', 'олтмиш', 'етмиш', 'саксон', 'тўқсон'];
	}

	function toWords(number) {
		if (number === 0) return 'nol';
		if (number < 0) return 'manfiy ' + toWords(Math.abs(number));
		let result = '';

		if (number >= 1000) {
			result += toWords(Math.floor(number / 1000)) + ' ming ';
			number %= 1000;
		}

		if (number >= 100) {
			result += ones[Math.floor(number / 100)] + ' yuz ';
			number %= 100;
		}

		if (number >= 10) {
			result += tens[Math.floor(number / 10)] + ' ';
			number %= 10;
		}

		if (number > 0) {
			result += ones[number] + ' ';
		}

		return result.trim();
	}

	return toWords(number);

}

export function numberToWordsRu(number) {
	const ones = ['', 'bir', 'ikki', 'uch', 'to\'rt', 'besh', 'olti', 'yetti', 'sakkiz', 'to\'qqiz'];
	const tens = ['', 'o\'n', 'yigirma', 'ottiz', 'qirq', 'ellik', 'oltmish', 'yetmish', 'sakson', 'to\'qson'];

	function toWords(number) {
		if (number === 0) return 'nol';
		if (number < 0) return 'manfiy ' + toWords(Math.abs(number));
		let result = '';

		if (number >= 1000) {
			result += toWords(Math.floor(number / 1000)) + ' ming ';
			number %= 1000;
		}

		if (number >= 100) {
			result += ones[Math.floor(number / 100)] + ' yuz ';
			number %= 100;
		}

		if (number >= 10) {
			result += tens[Math.floor(number / 10)] + ' ';
			number %= 10;
		}

		if (number > 0) {
			result += ones[number] + ' ';
		}

		return result.trim();
	}

	return toWords(number);
}

export function sortByNumber(array) {
	return [...array].sort((a, b) => a.name - b.name);
};

export const handleFocus = (event) => event.target.select();


export function updateReportData(reportData) {
	if (reportData) {
		const reportDataCopy = { ...reportData }
		reportDataCopy.reportData.shift.updated_at = new Date()
		return reportDataCopy.reportData;
	}
}