import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

import { Dropdown, Modal } from 'react-bootstrap'
import DeleteModal from "pages/parts/DeleteModal"
import Select from 'react-select'
import DatePicker from "react-datepicker"

import { formatDate, formatDateBackend, formatMoney, handleFocus } from 'helpers/helpers'
import { DELETE, GET } from 'helpers/api'

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const date = new Date()

	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

	const [data, setData] = useState([])
	const [fuels, setFuels] = useState([])

	const [selectedItem, setSelectedItem] = useState({})

	const [filterData, setFilterData] = useState({
		fuel_id: null,
		start_date: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		end_date: formatDateBackend(new Date()),
	})

	// async function createUpdate(e) {
	// 	e.preventDefault();
	// 	var selectedItemCopy = { ...selectedItem }
	//  await PUT('/fuel-in', selectedItemCopy)
	// 	setSelectedItem({})
	// 	setShowModal(true)
	// 	getData()
	// }

	async function deleteItem() {
		var selectedItemCopy = { ...selectedItem }
		await DELETE('/fuel-in/' + selectedItemCopy.id, {})
		setSelectedItem({})
		setShowDeleteModal(false)
		getData()
	}

	function openDeleteModal(item) {
		setSelectedItem({ id: item.id })
		setShowDeleteModal(true)
	}

	async function getFuels() {
		const response = await GET('/fuel-helper')
		if (response) {
			response.unshift({ id: null, name: t('ALL') })
			setFuels(response)
		}
	}

	async function getData() {
		const response = await GET('/fuel-in', filterData, { loader: true })
		if (response) {
			setData(response)
			setDropdownIsOpen(false)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
			getFuels()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="custom-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center w-100">
						<h4 className="me-2">{t('FUEL_IN')}</h4>
						<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
							<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
								{t('FILTER')}
							</Dropdown.Toggle>

							<Dropdown.Menu className="dropdown-filter">
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>{t('FUEL')}</label>
											<Select
												options={fuels}
												value={fuels.find(option => option.id === filterData.fuel_id)}
												onChange={(option) => setFilterData({ ...filterData, 'fuel_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })}
													onFocus={(e) => handleFocus(e)}
												/>
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })}
													onFocus={(e) => handleFocus(e)}
												/>
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>

								</div>
								<div className="row mt-3">
									<div className="col-md-12">
										<div className="d-flex justify-content-end">
											<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
											<button className="btn btn-primary" onClick={() => getData()}>{t('APPLY')}</button>
										</div>
									</div>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('SERIAL')}</th>
											<th className="text-center">{t('TTN')}</th>
											<th className="text-center">{t('IN_BALANCE')}</th>
											<th className="text-center">{t('AMOUNT')}</th>
											<th className="text-center">{t('OIL_STORAGE')}</th>
											<th className="text-center">{t('CARPOOL')}</th>
											<th className="text-center">{t('CAR')}</th>
											<th className="text-center">{t('FUEL')}</th>
											<th className="text-center">{t('STORAGE_TANK')}</th>
											<th className="text-center">{t('NOTE')}</th>
											<th className="text-center">{t('DATE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{item.serial}</td>
												<td className="text-center">{item.tin}</td>
												<td className="text-center">{formatMoney(item.mass_liter)}</td>
												<td className="text-center">{formatMoney(item.amount)}</td>
												<td className="text-center">{item.oil_storage_name}</td>
												<td className="text-center">{item.carpool_name}</td>
												<td className="text-center">{item.car_name}</td>
												<td className="text-center">{item.fuel_name}</td>
												<td className="text-center">{item.storage_tank_name}</td>
												<td className="text-center">{item.note}</td>
												<td className="text-center">{formatDate(item.receipt_date)}</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-danger-button" onClick={() => openDeleteModal(item)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<DeleteModal
				modalDelete={showDeleteModal}
				setModalDelete={setShowDeleteModal}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index