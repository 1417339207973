const initialState = {
	'shiftData': {},
	'calibrations': {},
	'updateShift': 0,
}

const settings = function (state = initialState, action) {
	switch (action.type) {
		case "SET_REPORT_DATA":
			return { ...state, 'reportData': action.payload };
		case "SET_CALIBRATION":
			return { ...state, 'calibrations': action.payload };
		case "UPDATE_SHIFT":
			return { ...state, 'updateShift': action.payload };
		default:
			return state;
	}
};

export default settings