import React from 'react'

import Test from '../pages/Test'

import Login from '../pages/auth/Login'
import ForgotPassword from "../pages/auth/ForgotPassword"

// Accountant
import AccountantDailyReport from '../pages/accountant/DailyReport'
import AccountantBalanceReport from '../pages/accountant/BalanceReport'

// Reports
import ShiftReport from '../pages/director/report/ShiftReport'

// ADMIN
import AdminUser from '../pages/admin/users/Index'
import AdminPos from '../pages/admin/pos/Index'
import AdminAccountant from '../pages/admin/accountant/Index'
import AdminDirector from '../pages/admin/director/Index'
import AdminCashier from '../pages/admin/cashier/Index'

const publicRoutes = [
	{ path: "/test", component: <Test /> },
	{ path: "/auth/login", component: <Login /> },
	{ path: "/auth/forgot-password", component: <ForgotPassword /> },
]

const cashierRoutes = [
	{ path: "/shift-report", component: <ShiftReport /> },
	{ path: "/shift-report/:id", component: <ShiftReport /> },
]

const accountantPrivateRoutes = [
	{ path: "/accountant/daily-report", component: <AccountantDailyReport /> },
	{ path: "/accountant/balance-report", component: <AccountantBalanceReport /> },
]

const adminPrivateRoutes = [
	// ADMIN
	{ path: "/admin/users", component: <AdminUser /> },
	{ path: "/admin/poses", component: <AdminPos /> },
	{ path: "/admin/accountant", component: <AdminAccountant /> },
	{ path: "/admin/director", component: <AdminDirector /> },
	{ path: "/admin/cashier", component: <AdminCashier /> },
]

export { publicRoutes, accountantPrivateRoutes, adminPrivateRoutes, cashierRoutes }