import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
//import { useSelector } from 'react-redux'


function FilterPagination({ pagination = {}, paginate }) {
	//const settings = useSelector(state => state.settings)

	const [pageCount, setPageCount] = useState(0)
	const [inputPage, setInputPage] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)

	async function getCurrentPage(pageNumber) {
		paginate(pageNumber.selected)
	}

	function handlePageClick() {
		const newPage = parseInt(inputPage)

		if (newPage >= 1) {
			setCurrentPage(newPage - 1)
			getCurrentPage({ 'selected': newPage - 1 })
		}
	}

	useEffect(() => {
		if (pagination?.response) {
			setPageCount(Math.ceil(pagination?.response?.total / 20))
		}
	}, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{pageCount > 1 &&
				<div className="d-flex">
					<div className="pagination position-relative">
						<input type="number" className="pagination-input"
							onChange={(e) => setInputPage(e.target.value)}
							onKeyUp={(event) => {
								if (event.key === 'Enter') {
									handlePageClick()
								}
							}}
						/>
						<div className="pagination-input-icon" onClick={() => handlePageClick()}>
							<i className="uil uil-angle-right"></i>
						</div>
					</div>

					<ReactPaginate
						forcePage={currentPage}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={3}
						pageRangeDisplayed={3}
						onPageChange={getCurrentPage}
						containerClassName={'pagination'}
						activeClassName={'active'}
						pageClassName={'page-item'}
					/>
				</div>
			}
		</>
	)
}

export default FilterPagination
