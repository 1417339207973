export let exportMenu = [
	{
		icon: "sidebar-icon uil-chart-growth",
		children: [
			{
				title: true,
				i18n: "REPORT",
			},
			{
				url: "/accountant/daily-report",
				i18n: "SHIFT_REPORT",
				icon: "uil-chart-growth",
				active: false,
			},
			{
				url: "/accountant/balance-report",
				i18n: "BALANCE2",
				icon: "uil-money-bill",
				active: false,
			},
		]
	},
]