import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'

import { SET_SHIFT_DATA } from 'store/actions'

import { formatDateBackend } from 'helpers/helpers'
import { POST } from 'helpers/api'
import { UPDATE_SHIFT } from 'store/report/actions'

function ShiftOpen({ showModal, setShowModal }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxReportData = useSelector(state => state.report)
	const reduxShiftData = useSelector(state => state.shiftData)

	const [shiftDate, setShiftDate] = useState(formatDateBackend(new Date()))

	async function openShift() {
		const response = await POST('/shift-open', {
			open_date: new Date(shiftDate)
		})
		if (response) {
			dispatch(SET_SHIFT_DATA(response.data))
			dispatch(UPDATE_SHIFT((reduxReportData.updateShift ?? 0) + 1))
			setShowModal(false)
			// toast.success(t('SUCCESS'))
		}
	}

	return (
		<>
			<Modal show={showModal} animation={false} size='md'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<h4>{t('OPEN_SHIFT')}</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group me-2">
						<label>{t('LAST_SHIFT')}</label>
						<input
							autoFocus
							mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/,]}
							className="form-control"
							placeholder="xx.xx.xxxx"
							value={reduxShiftData?.closed_shift?.opened_at}
							type='date'
							disabled
						/>
					</div>

					<div className="form-group me-2">
						<label>{t('OPEN_DATE')}</label>
						<input
							autoFocus
							mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/,]}
							className="form-control"
							placeholder="xx.xx.xxxx"
							value={shiftDate}
							type='date'
							onChange={(e) => setShiftDate(e.target.value)}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100 gap-3">
						<button className="btn btn-danger w-100" onClick={() => setShowModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => openShift()}>
							{t('OPEN')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ShiftOpen