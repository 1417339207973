import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function CustomTooltip({ children, text }) {
	return (
		<>
			<OverlayTrigger
				overlay={text ?
					<Tooltip style={{ position: "fixed" }} id={text}>
						{text}
					</Tooltip>
					:
					<span></span>}
				placement="top"
				delayShow={300}
				delayHide={150}
				trigger={["hover", "hover"]}
			>
				<div>
					{children}
				</div>
			</OverlayTrigger>
		</>
	)
}

export default CustomTooltip