import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import DailyReportModal from 'pages/director/report/DailyReport'
import CustomTooltip from 'pages/parts/CustomTooltip'
import { Modal } from 'react-bootstrap'
import ConfirmModal from 'pages/parts/ConfirmModal'
import Select from 'react-select'
import ShiftOpen from 'pages/director/modals/ShiftOpen'
import ChangeFuel from 'pages/director/modals/ChangeFuel'
import ReportFuelInModal from 'pages/director/report/FuelIn';

import { DELETE, GET, POST } from 'helpers/api'
import { SET_SHIFT_DATA } from 'store/actions'
import { SET_REPORT_DATA, UPDATE_SHIFT } from 'store/report/actions'
import { formatDate, formatDateWithTime, formatMoney, formatTime, updateReportData } from 'helpers/helpers'


function Footer() {
	const { t } = useTranslation()

	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { id } = useParams()

	const reduxReportData = useSelector(state => state.report)
	const reduxShiftData = useSelector(state => state.shiftData)

	const [showModal, setShowModal] = useState(false)
	const [showShiftModal, setShowShiftModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
	const [showOperatorModal, setShowOperatorModal] = useState(false)
	const [showCollectionModal, setShowCollectionModal] = useState(false)
	const [showChangeFuelModal, setShowChangeFuelModal] = useState(false)
	const [showReportFuelInModal, setShowReportFuelInModal] = useState(false)

	const [operators, setOperators] = useState([])
	const [collection, setCollection] = useState([])
	const [selectedOperator, setSelectedOperator] = useState({
		accepted: {},
		passed: {}
	})

	function goToFirst() {
		navigate(`/shift-report/${reduxShiftData.total_shifts}`)
	}

	function goToLast() {
		if (location.pathname === '/shift-report') {
			updateCurrentShift()
		}
		navigate(`/shift-report`)
	}

	function goBack() {
		var offset = 1;
		if (location.pathname === '/shift-report') {
			updateCurrentShift()
			navigate(`/shift-report/1`)
		} else {
			offset += Number(location.pathname.split('/')[2] ?? 0)
			navigate(`/shift-report/${offset}`)
		}
	}

	function goNext() {
		if (location.pathname === '/shift-report/1') {
			navigate(`/shift-report`)
		} else {
			var offset = Number(location.pathname.split('/')[2] ?? 0) - 1
			navigate(`/shift-report/${offset}`)
		}
	}

	async function closeShift() {
		var sendData = { ...reduxReportData.reportData }
		const response = await POST('/shift-close', sendData, { loader: true })
		if (response) {
			dispatch(SET_SHIFT_DATA({}))
			dispatch(SET_REPORT_DATA({}))
			dispatch(UPDATE_SHIFT((reduxReportData.updateShift ?? 0) + 1))
			setShowConfirmModal(false)
		}
	}

	async function editShift() {
		var offset = Number(location.pathname.split('/')[2] ?? 0)
		var sendData = { ...reduxReportData.reportData }
		sendData.offset = offset
		const response = await POST('/update-shift', sendData, { loader: true, toast: true })
		if (response) { }
	}

	async function removeShift() {
		var sendData = { ...reduxReportData.reportData }
		const response = await DELETE('/shift/' + sendData.shift.id)
		if (response) {
			dispatch(SET_SHIFT_DATA({}))
			dispatch(SET_REPORT_DATA({}))
			dispatch(UPDATE_SHIFT((reduxReportData.updateShift ?? 0) + 1))
			setShowDeleteConfirmModal(false)
		}
	}

	async function updateCurrentShift(toast = false) {
		var sendData = { ...reduxReportData.reportData }
		await POST('/update-current-shift', sendData, { loader: false, toast: toast })
		dispatch(SET_REPORT_DATA(updateReportData(reduxReportData)))
	}

	async function getCollection() {
		const response = await GET('/collection')
		if (response) {
			setCollection(response)
			setShowCollectionModal(true)
		}
	}

	async function getShift() {
		const response = await GET('/openned-shift')
		if (response) {
			dispatch(SET_SHIFT_DATA(response))
		}
	}

	async function getOperators() {
		const response = await GET('/operator-helper')
		if (response) {
			setOperators(response)
		}
	}

	useEffect(() => {
		getShift()
		getOperators()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>

			<footer className="footer buttons__container">
				<CustomTooltip text={t('FIRST_SHIFT')}>
					<button onClick={() => goToFirst()}
						disabled={(id === reduxShiftData?.total_shifts?.toString()) || reduxShiftData?.total_shifts === 0}>
						<i className="uil uil-step-backward-circle"></i>
					</button>
				</CustomTooltip>
				<CustomTooltip text={t('BACK')}>
					<button onClick={() => goBack()}
						disabled={(id === reduxShiftData?.total_shifts?.toString()) || reduxShiftData?.total_shifts === 0}>
						<i className="uil uil-arrow-circle-left"></i>
					</button>
				</CustomTooltip>
				<CustomTooltip text={t('FORWARD')}>
					<button onClick={() => goNext()} title='Вперед' disabled={!id}>
						<i className="uil uil-arrow-circle-right"></i>
					</button>
				</CustomTooltip>
				<CustomTooltip text={t('CURRENT_SHIFT')}>
					<button onClick={() => goToLast()} disabled={!id} title='Последняя'>
						<i className="uil uil-skip-forward-circle"></i>
					</button>
				</CustomTooltip>


				{!id &&
					<>
						{
							!reduxShiftData.id ?
								<CustomTooltip text={t('OPEN_SHIFT')}>
									<button onClick={() => setShowShiftModal(true)} title=''>
										<i className="uil uil-unlock-alt"></i>
									</button>
								</CustomTooltip>
								:
								<CustomTooltip text={t('CLOSE_SHIFT')}>
									<button onClick={() => setShowConfirmModal(true)}>
										<i className="uil uil-lock-alt"></i>
									</button>
								</CustomTooltip>
						}
					</>
				}
				<CustomTooltip text={t('Инкассация')}>
					<button onClick={() => getCollection()}>
						<i className="uil uil-university"></i>
					</button>
				</CustomTooltip>
				<CustomTooltip text={t('Накладные')}>
					<button onClick={() => setShowOperatorModal(true)} disabled>
						<i className="uil uil-file-alt"></i>
					</button>
				</CustomTooltip>
				<CustomTooltip text={'Количество записей: ' + reduxShiftData.total_shifts}>
					<button>
						<i className="uil uil-diary"></i>
					</button>
				</CustomTooltip>
				<CustomTooltip text={'Операторы'}>
					<button disabled>
						<i className="uil uil-user-md"></i>
					</button>
				</CustomTooltip>
				<CustomTooltip text={t('PRINT')}>
					<button onClick={() => setShowOperatorModal(true)}>
						<i className="uil uil-print"></i>
					</button>
				</CustomTooltip>
				{id ?
					<CustomTooltip text={t('EDIT')}>
						<button onClick={() => editShift()}>
							<i className="uil uil-save"></i>
						</button>
					</CustomTooltip>
					:
					<CustomTooltip text={t('SAVE')}>
						<button onClick={() => updateCurrentShift(true)}>
							<i className="uil uil-save"></i>
						</button>
					</CustomTooltip>
				}
				<CustomTooltip text={t('DELETE')}>
					<button onClick={() => setShowDeleteConfirmModal(true)} disabled={!reduxReportData.reportData?.shift?.id}>
						<i className="uil uil-trash-alt"></i>
					</button>
				</CustomTooltip>
				{/* {!id &&
					<CustomTooltip text={t('CLEAR_AND_CHANGE_FUEL')}>
						<button onClick={() => setShowChangeFuelModal(true)}>
							<i className="uil uil-exchange"></i>
						</button>
					</CustomTooltip>
				} */}

				<div className="version">


					{reduxReportData?.reportData?.shift?.id &&
						<>
							{reduxReportData?.reportData?.shift?.closed_at &&
								<>
									<div>
										<div>{t('shift_closed_date')}</div>
										<b>
											{formatDateWithTime(reduxReportData.reportData?.shift?.closed_at ?? new Date())}
										</b>
									</div>

									<div className="version-devider"></div>
								</>
							}

							<div>
								<div>{t('last_save')}</div>
								<b>
									{formatTime(reduxReportData?.reportData?.shift?.updated_at ?? new Date())}
								</b>
							</div>

							<div className="version-devider"></div>
						</>
					}

					<div>
						<div>{t('VERSION')}</div>
						<b>{process.env.REACT_APP_VERSION}</b>
					</div>
				</div>
			</footer>

			<DailyReportModal showModal={showModal} setShowModal={setShowModal} selectedOperators={selectedOperator} />

			<ShiftOpen showModal={showShiftModal} setShowModal={setShowShiftModal} />

			<Modal show={showOperatorModal} animation={false} size='md'
				centered dialogClassName="update-modal-width" onHide={() => setShowOperatorModal(false)}>
				<Modal.Header closeButton>
					<h4>{t('PRINT')}</h4>
				</Modal.Header>
				<div className="d-flex gap-3 m-3">
					<div className="form-group">
						<label>{t('passed_shift')}</label>
						<Select
							options={operators}
							value={selectedOperator.accepted}
							onChange={(option) => setSelectedOperator({ ...selectedOperator, 'accepted': option })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					<div className="form-group">
						<label>{t('accepted_shift')}</label>
						<Select
							options={operators}
							value={selectedOperator.passed}
							onChange={(option) => setSelectedOperator({ ...selectedOperator, 'passed': option })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
				</div>
				<Modal.Footer>
					<div className="d-flex w-100 gap-3">
						<button className="btn btn-danger w-100" onClick={() => setShowOperatorModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => {
							setShowOperatorModal(false)
							setShowModal(true)
						}}>
							{t('OPEN')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showCollectionModal}
				onHide={() => setShowCollectionModal(false)}
				keyboard={false}
				size='xs'
				centered
			>
				<Modal.Header className='px-4 py-2' closeButton>
					<Modal.Title>Инкассация</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='table-responsive'>
						<table className='table table-sm table-bordered mb-0'>
							<thead>
								<tr className="tr-start" >
									<th>№</th>
									<th>{t('DATE')}</th>
									<th className="text-end">{t('AMOUNT')}</th>
								</tr>
							</thead>
							<tbody>
								{collection?.map((item, index) => (
									<tr className="tr-start" key={index}>
										<td>{index + 1}</td>
										<td>{formatDate(item.opened_at)}</td>
										<td className="text-end">{formatMoney(item.deposited_to_bank)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>

			<ChangeFuel showChangeFuelModal={showChangeFuelModal} setShowChangeFuelModal={setShowChangeFuelModal} />

			<ReportFuelInModal showModal={showReportFuelInModal} setShowModal={setShowReportFuelInModal} />

			<ConfirmModal confrim={closeShift} modalConfirm={showConfirmModal} setModalConfirm={setShowConfirmModal}
				description={t('ARE_YOU_SURE_YOU_WANT_CLOSE_SHIFT', { date: formatDate(reduxReportData?.reportData?.shift?.opened_at) })} />

			<ConfirmModal confrim={removeShift} modalConfirm={showDeleteConfirmModal} setModalConfirm={setShowDeleteConfirmModal}
				description={t('ARE_YOU_SURE_YOU_WANT_DELETE_SHIFT', { date: formatDate(reduxReportData?.reportData?.shift?.opened_at) })} />
		</>
	)
}

export default Footer