import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { formatDate } from 'helpers/helpers'
import { DELETE, GET, POST, PUT } from 'helpers/api'
import DeleteModal from "pages/parts/DeleteModal"

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const fuelTypes = [{ id: 1, name: t('gasoline') }, { id: 2, name: t('diesel') }]
	const fuelHelper = [
		{
			id: 1,
			name: '80',
			gtin: '02710001003000000',
			package_code: '1282118',
		},
		{
			id: 2,
			name: '92',
			gtin: '02710001005000000',
			package_code: '1282556',
		},
		{
			id: 3,
			name: '95',
			gtin: '02710001007000000',
			package_code: '1282627',
		},
		{
			id: 4,
			name: '100',
			gtin: '02710001010000000',
			package_code: '1282258',
		},
	]

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'short_name': '',
		'fuel_type': '',
	})
	const [data, setData] = useState([])
	const [showCreateModal, setShowCreateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		if (!selectedItemCopy.id) {
			await POST('/fuel', selectedItemCopy)
		} else {
			await PUT('/fuel', selectedItemCopy)
		}

		setSelectedItem({})
		setShowModal(true)
		setShowCreateModal(false)
		getData()
	}

	async function deleteItem() {
		var selectedItemCopy = { ...selectedItem }
		await DELETE('/fuel/' + selectedItemCopy.id, {})
		setSelectedItem({})
		setShowDeleteModal(false)
		getData()
	}

	function openModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem(selectedItemCopy)
		setShowModal(false)
		setShowCreateModal(true)
	}

	function openDeleteModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem({ id: selectedItemCopy.id })
		setShowDeleteModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'name': '',
			'short_name': '',
			'fuel_type': '',
		})
		setShowModal(true)
		setShowCreateModal(false)
	}

	async function getData() {
		const response = await GET('/fuel', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('FUEL')}</h4>
						<button className="btn-rounded btn btn-primary me-2" onClick={() => openModal()}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('NAME')}</th>
											<th className="text-center">{t('FUEL_TYPE')}</th>
											<th className="text-center">{t('gtin')}</th>
											<th className="text-center">{t('package_code')}</th>
											<th className="text-center">{t('DATE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{item.name}</td>
												<td className="text-center">{fuelTypes.find(element => element.id === item.fuel_type).name}</td>
												<td className="text-center">{item.gtin}</td>
												<td className="text-center">{item.package_code}</td>
												<td className="text-center">{formatDate(item.created_at)}</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button me-2" onClick={() => openModal(item)}>
														<i className="uil-edit-alt"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => openDeleteModal(item)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* MODAL */}
			<Modal show={showCreateModal} animation={false} centered dialogClassName="update-modal-width" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('CREATE')}</span>
							:
							<span>{t('EDIT')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('FUEL_TYPE')}</label>
							<Select
								options={fuelTypes}
								value={fuelTypes.find(option => option.id === selectedItem.fuel_type)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'fuel_type': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="form-group">
							<label>{t('FUEL_TYPE')}</label>
							<Select
								options={fuelHelper}
								value={fuelHelper.find(option => option.id === selectedItem.gtin)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'gtin': option.gtin, 'package_code': option.package_code })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('gtin')}</label>
							<input type="text" className="form-control" name="gtin" value={selectedItem.gtin}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'gtin': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('package_code')}</label>
							<input type="text" className="form-control" name="package_code" value={selectedItem.package_code}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'package_code': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={showDeleteModal}
				setModalDelete={setShowDeleteModal}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index