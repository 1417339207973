import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { GET, POST } from 'helpers/api'
import { UPDATE_SHIFT } from 'store/report/actions'
import { formatDateBackend } from 'helpers/helpers'

function ChangeFuel({ showChangeFuelModal, setShowChangeFuelModal }) {
	const { t } = useTranslation()

	const dispatch = useDispatch()

	const reduxReportData = useSelector(state => state.report)

	const date = new Date()
	const defaultValue = date.toLocaleDateString('en-CA');

	const [fuels, setFuels] = useState([])
	const [tanks, setTanks] = useState([])
	const [selectedItem, setSelectedItem] = useState({
		'fuel_id': '',
		'fuel_name': '',
		'storage_tank_id': '',
		'shift_date': formatDateBackend(new Date()),
	})

	async function changeFuel(e) {
		e.preventDefault();

		const response = await POST('/change-fuel', selectedItem)
		if (response) {
			closeChangeFuelModal()
			dispatch(UPDATE_SHIFT((reduxReportData.updateShift ?? 0) + 1))
		}
	}

	function closeChangeFuelModal() {
		setSelectedItem({
			'fuel_id': '',
			'fuel_name': '',
			'storage_tank_id': '',
			'shift_date': formatDateBackend(new Date()),
		})
		setShowChangeFuelModal(false)
	}

	function selectFuel(option) {
		setTanks(option.tanks)
		setSelectedItem({ ...selectedItem, 'fuel_id': option.id, 'storage_tank_id': option.tanks[0]?.id, 'fuel_name': option.name })
	}

	async function getData() {
		const responseFuels = await GET('/fuel-helper')
		if (responseFuels) {
			setFuels(responseFuels)
			selectFuel(responseFuels[0])
		}
	}

	useEffect(() => {
		if (showChangeFuelModal) {
			getData()
		}
	}, [showChangeFuelModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal
				show={showChangeFuelModal}
				onHide={() => setShowChangeFuelModal(false)}
				keyboard={false}
				size='md'
				centered
			>
				<Modal.Header className='px-4 py-2' closeButton>
					<Modal.Title>{t('CLEAR_AND_CHANGE_FUEL')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => changeFuel(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('FUEL')}</label>
							<Select
								options={fuels}
								value={fuels.find(option => option.id === selectedItem.fuel_id)}
								onChange={(option) => selectFuel(option)}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('STORAGE_TANK')}</label>
							<Select
								options={tanks}
								value={tanks.find(option => option.id === selectedItem.storage_tank_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'storage_tank_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('NAME')}</label>
							<input type="text" className="form-control" name="fuel_name" value={selectedItem.fuel_name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'fuel_name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DATE')}</label>
							<input
								className="form-control"
								defaultValue={defaultValue}
								type="date"
								autoFocus
								onChange={(e) => setSelectedItem({ ...selectedItem, shift_date: e.target.value })}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeChangeFuelModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ChangeFuel