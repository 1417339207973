import React from 'react'
import { Outlet } from 'react-router-dom';

import Header from "../accountant/Header";
import Sidebar from "./Sidebar";
import { useSelector } from 'react-redux'

function Layout() {
	const layoutSelector = useSelector(state => state.Layout)

	return (
		<div id="layout-wrapper">
			<Header />
			<Sidebar />
			<div className={"main-content " + (layoutSelector.showSidebar ? 'sidebar-margin' : 'sidebar-active-margin')}>
				<div className="page-content">
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export default Layout