import React from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { publicRoutes, accountantPrivateRoutes, adminPrivateRoutes, cashierRoutes } from './routes'

import Layout from 'containers/layout/accountant/Layout';
import LayoutAdmin from 'containers/layout/admin/Layout';
import CashierLayout from 'containers/layout/director/Layout';
import NoAuthLayout from 'containers/layout/NoAuthLayout';

function createPublicRoutes() {
	return publicRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}

function createAccountantPrivateRoutes() {
	const role = localStorage.getItem("role");
	if (role === 'ROLE_ACCOUNTANT') {
		return accountantPrivateRoutes.map((item, key) => {
			return (
				<Route key={key} element={<ProtectedRoute />}>
					<Route path={item.path} element={item.component} />
				</Route>
			);
		});
	}
}

function createCashierPrivateRoutes() {
	const role = localStorage.getItem("role");

	if (role === 'ROLE_DIRECTOR') {
		return cashierRoutes.map((item, key) => {
			return (
				<Route key={key} element={<ProtectedCashierRoute />}>
					<Route path={item.path} element={item.component} />
				</Route>
			);
		});
	}
}

function createAdminPrivateRoutes() {
	const role = localStorage.getItem("role");

	if (role === 'ROLE_ADMIN') {
		return adminPrivateRoutes.map((item, key) => {
			return (
				<Route key={key} element={<ProtectedAdminRoute />}>
					<Route path={item.path} element={item.component} />
				</Route>
			);
		});
	}
}

function createRedirectRoutes() {
	const role = localStorage.getItem("role");

	if (role === 'ROLE_ADMIN') {
		return (
			<Route path="*" element={<Navigate to="/admin/users" replace />} />
		);
	}
	if (role === 'ROLE_ACCOUNTANT') {
		return (
			<Route path="*" element={<Navigate to="/accountant/daily-report" replace />} />
		);
	}
}

function ProtectedRoute() {
	const login = localStorage.getItem("login");
	var isAuthenticated = false;
	if (login) {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('login')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

function ProtectedAdminRoute() {
	const login = localStorage.getItem("login");
	const role = localStorage.getItem("role");

	var isAuthenticated = false;
	if (login === 'admin' && role === 'ROLE_ADMIN') {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('login')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

function ProtectedCashierRoute() {
	const role = localStorage.getItem("role");

	var isAuthenticated = false;
	if (role === 'ROLE_DIRECTOR') {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('login')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

const Index = () => {
	return (
		<Routes>
			<Route element={<NoAuthLayout />}>
				{createPublicRoutes()}
			</Route>

			<Route element={<CashierLayout />}>
				{createCashierPrivateRoutes()}
			</Route>

			<Route element={<NoAuthLayout />}>
				<Route path="*" element={<Navigate to="/auth/login" replace />} />
			</Route>

			<Route element={<Layout />}>
				{createAccountantPrivateRoutes()}
			</Route>

			<Route element={<NoAuthLayout />}>
				{createRedirectRoutes()}
			</Route>

			<Route path="/admin" element={<LayoutAdmin />}>
				<Route path="/admin" element={<Navigate to="/admin/users" replace />} />
				{createAdminPrivateRoutes()}
			</Route>

		</Routes>
	)
}

export default Index;