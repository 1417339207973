import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import CalibrationModal from "./Calibration"

import { formatDate } from 'helpers/helpers'
import { DELETE, GET, POST, PUT } from 'helpers/api'
import DeleteModal from "pages/parts/DeleteModal"

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'fuel_id': '',
		'capacity': '',
		'height': '',
		'min_volume': '',
	})
	const [fuels, setFuels] = useState([])
	const [data, setData] = useState([])
	const [showCreateModal, setShowCreateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [calibrationModal, setCalibrationModal] = useState({
		show: false,
		id: 0
	})

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		if (!selectedItemCopy.id) {
			await POST('/storage-tank', selectedItemCopy)
		} else {
			await PUT('/storage-tank', selectedItemCopy)
		}

		setSelectedItem({})
		setShowModal(true)
		setShowCreateModal(false)
		getData()
	}

	async function deleteItem() {
		var selectedItemCopy = { ...selectedItem }
		await DELETE('/storage-tank/' + selectedItemCopy.id, {})
		setSelectedItem({})
		setShowDeleteModal(false)
		getData()
	}

	function openModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem(selectedItemCopy)
		setShowModal(false)
		setShowCreateModal(true)
	}

	function openCalibrationModal(item) {
		setShowModal(false)
		setCalibrationModal({
			show: true,
			id: item.id
		})
	}

	function closeCalibrationModal() {
		setShowModal(true)
		setCalibrationModal({
			show: false,
			id: 0
		})
	}

	function openDeleteModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem({ id: selectedItemCopy.id })
		setShowDeleteModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'name': '',
			'fuel_id': '',
			'capacity': '',
			'height': '',
			'min_volume': '',
		})
		setShowModal(true)
		setShowCreateModal(false)
	}

	async function getData() {
		const response = await GET('/storage-tank', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	async function getFuels() {
		const response = await GET('/fuel-helper')
		if (response) {
			setFuels(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
			getFuels()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('STORAGE_TANK')}</h4>
						<button className="btn-rounded btn btn-primary me-2" onClick={() => openModal()}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('NAME')}</th>
											<th className="text-center">{t('CAPACITY')}</th>
											<th className="text-center">{t('HEIGHT')}</th>
											<th className="text-center">{t('MIN_VOLUME')}</th>
											<th className="text-center">{t('FUEL')}</th>
											<th className="text-center">{t('DATE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{item.name}</td>
												<td className="text-center">{item.capacity}</td>
												<td className="text-center">{item.height}</td>
												<td className="text-center">{item.min_volume}</td>
												<td className="text-center">{item.fuel_name}</td>
												<td className="text-center">{formatDate(item.created_at)}</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button me-2" onClick={() => openModal(item)}>
														<i className="uil-edit-alt"></i>
													</div>
													<div className="table-action-button table-action-danger-button me-2" onClick={() => openDeleteModal(item)}>
														<i className="uil-trash-alt"></i>
													</div>
													<div className="table-action-button table-action-primary-button"
														onClick={() => openCalibrationModal(item)} title="Калибровка">
														<i className="uil-list-ol"></i>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* MODAL */}
			<Modal show={showCreateModal} animation={false} centered dialogClassName="update-modal-width" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('CREATE')}</span>
							:
							<span>{t('EDIT')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('CAPACITY')}</label>
							<input type="number" className="form-control" name="capacity" value={selectedItem.capacity}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'capacity': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('HEIGHT')}</label>
							<input type="number" className="form-control" name="height" value={selectedItem.height}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'height': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('MIN_VOLUME')}</label>
							<input type="number" className="form-control" name="min_volume" value={selectedItem.min_volume}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'min_volume': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('FUEL')}</label>
							<Select
								options={fuels}
								value={fuels.find(option => option.id === selectedItem.fuel_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'fuel_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={showDeleteModal}
				setModalDelete={setShowDeleteModal}
				deleteItem={deleteItem}>
			</DeleteModal>

			<CalibrationModal
				showModal={calibrationModal.show}
				id={calibrationModal.id}
				setShowModal={closeCalibrationModal}>
			</CalibrationModal>
		</>
	)
}

export default Index