
import ru from "../assets/images/flags/ru.png"
import uzCyrl from "../assets/images/flags/uz_cyrl.png"
import uzLatn from "../assets/images/flags/uz_latn.png"

const languages = {
  ru: {
    label: "ru",
    i18n: "RUSSIAN",
    flag: ru,
  },
	uzCyrl: {
    label: "uz_cyrl",
		i18n: "UZBEK_CYRL",
    flag: uzCyrl,
  },
	uzLatn: {
    label: "uz_latn",
		i18n: "UZBEK_LATN",
    flag: uzLatn,
  },
}

export default languages
