import React, { useState, useEffect } from 'react'

import questions from './questions.json';

function Test() {
	const [comments, setComments] = useState(questions.filter(item => item.bilet_number > 70))
	// const [images, setImages] = useState([])

	// async function imagesArray(e) {
	// 	const array = [];
	// 	for (let i = 0; i < e.target.files.length; i++) {
	// 		const item = e.target.files[i]
	// 		if (item.name.includes('a')) {
	// 			console.log('------------------------------------------------------------------------------------------------------------');
	// 			console.log(item.name.replaceAll('a', ''));
	// 			var question = questions.find(el => el.image_url === item.name.replaceAll('a', '').replaceAll('i', ''))
	// 			console.log(question);
	// 			array.push({
	// 				id: question?.id,
	// 				bilet_number: question?.bilet_number,
	// 				image: item.name,
	// 				img_url: item.name.replaceAll('a', '')
	// 			})
	// 		}
	// 	}
	// 	console.log(JSON.stringify(array));

	// 	setImages(array)
	// }

	async function showFile(e) {
		if (e.target) {
			e.preventDefault();
			var commentsCopy = [...comments];

			const filePromises = Array.from(e.target.files).map((file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = (e) => {
						let text = e.target.result;

						const parts = {};

						// Регулярные выражения для поиска частей текста
						const regexes = [
							{ key: 'question', regex: /^(.*?)F1/s },
							{ key: 'answer_a_ru', regex: /F1(.*?)(F2|Комментарий)/s },
							{ key: 'answer_b_ru', regex: /F2(.*?)(F3|Комментарий)/s },
							{ key: 'answer_c_ru', regex: /F3(.*?)(F4|Комментарий)/s },
							{ key: 'answer_d_ru', regex: /F4(.*?)(Комментарий)/s },
							{ key: 'comment', regex: /Комментарий:(.*)/s }
						];

						regexes.forEach(({ key, regex }) => {
							const match = text.match(regex);
							parts[key] = match ? match[1].trim() : '';
						});

						commentsCopy.push({
							"question_ru": parts.question,
							"answer_a_ru": parts.answer_a_ru,
							"answer_b_ru": parts.answer_b_ru,
							"answer_c_ru": parts.answer_c_ru,
							"answer_d_ru": parts.answer_d_ru,
							"comment_ru": parts.comment,
							"row_number": commentsCopy.length + 1 + 700,
							"bilet_number": Math.ceil((commentsCopy.length + 1) / 10),
							"is_new": true,
						});

						// text = text.replace(/^[ -]+|[ -]+$/g, '');
						// text = text.replace(/No/g, '№');
						// text = text.replace(/"/g, '\\"');
						// text = text.replace(/\n/g, ' ');
						// var index = questions.findIndex(item => item.id === commentsCopy.length + 1);
						// if (index !== -1) {
						//     commentsCopy.push({
						//         ...questions[index],
						//         "comment_uz_latn": text,
						//     });
						// }

						resolve();
					};
					reader.onerror = reject;
					reader.readAsText(file);
				});
			});

			await Promise.all(filePromises);
			e.target.value = null;
			console.log(commentsCopy);

			setComments(commentsCopy);
		}
	}

	const downloadJSON = () => {
		const dataStr = JSON.stringify(comments, null, 2);
		const blob = new Blob([dataStr], { type: "application/json" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'comments.json';
		link.click();

		URL.revokeObjectURL(url);
	};

	useEffect(() => {

		// const mergedJson = testJson.map(item1 => {
		//     const matchingItems = questions.filter(
		//         item2 =>
		//             item2.row_number === item1.row_number &&
		//             item2.bilet_number === item1.bilet_number
		//     );

		//     return matchingItems.map(matchingItem => ({ ...item1, ...matchingItem }));
		// }).flat();

		// setComments(mergedJson)
	}, []);

	return (
		<>
			<div className="container">
				<h3>Test</h3>
				{/* <input type="file" className="form-control" multiple onChange={(e) => imagesArray(e)} /> */}
				<input type="file" accept="text/plain" className="form-control" multiple onChange={(e) => showFile(e)} />

				<div className="tv-hot-tours tv-moduleid-9973421"></div>


				<button className="btn btn-primary my-2 w-100" onClick={downloadJSON}>Download JSON</button>

				{/* {images.map((item, index) => (
					<div key={index}>
						{JSON.stringify(item)}
					</div>
				))
				} */}

				<div style={{ overflowY: 'auto', height: '85vh' }}>
					{comments.map((item, index) => (
						<div key={index} className="my-2">
							{item.row_number} ({item.bilet_number})
							<b> {item.question_ru}</b> <br /> <br />
							a. {item.answer_a_ru} <br />
							b. {item.answer_b_ru} <br />
							c. {item.answer_c_ru} <br />
							d. {item.answer_d_ru} <br /> <br />
							{item.comment_ru}
						</div>
					))
					}
				</div>
			</div>
		</>
	)
}

export default Test