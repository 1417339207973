import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { formatDate, formatMoney, formatMoneyInput, regexPhoneNumber, returnSign } from 'helpers/helpers'
import { GET, POST, PUT, PGET } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({})
	const [users, setUsers] = useState([])
	// const [payments, setPayments] = useState([])
	// const [cashiers, setCashiers] = useState([])
	const [modalUpdate, setModalUpdate] = useState(false)
	const [modalPayment, setModalPayment] = useState(false)
	// const [modalInfo, setModalInfo] = useState(false)
	// const [modalCashiers, setModalCashiers] = useState(false)
	const [data, setData] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/admin/pos',
		response: null
	})

	async function searchWithFilter() {
		var filterUrl = "/admin/pos"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/admin/pos${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.phone = '998' + selectedItemCopy.phone
		if (!selectedItemCopy.id) {
			await POST('/admin/pos', selectedItemCopy, { loader: true })
		} else {
			await PUT('/admin/pos', selectedItemCopy, { loader: true })
		}

		setSelectedItem({})
		setModalUpdate(false)
		searchWithFilter()
	}

	async function payment(e) {
		e.preventDefault();

		await POST('/admin/pos-payment', selectedItem, { loader: true })

		setSelectedItem({})
		setModalPayment(false)
		searchWithFilter()
	}

	function openModalUpdate(item) {
		var selectedItemCopy = { ...item }
		selectedItemCopy.phone = selectedItemCopy.phone.substring(3, 12)
		setSelectedItem(selectedItemCopy)
		setModalUpdate(true)
	}

	// function openModalPayment(item) {
	// 	var selectedItemCopy = { ...item }
	// 	selectedItemCopy.payment = ""
	// 	selectedItemCopy.note = ""
	// 	setSelectedItem(selectedItemCopy)
	// 	setModalPayment(true)
	// }

	// async function openModalInfo(item) {
	// 	const response = await GET('/admin/pos-payment-detail', {
	// 		'login': item.login,
	// 		'id': item.id,

	// 	}, { loader: true })
	// 	setPayments(response)
	// 	setModalInfo(true)
	// }

	// async function openModalCashiers(item) {
	// 	const response = await GET('/admin/pos-cashiers', {
	// 		'id': item.id,
	// 	}, { loader: true })
	// 	setCashiers(response)
	// 	setModalCashiers(true)
	// }

	// async function handleChangeActivated(e, index, type, index2) {
	// 	var dataCopy = { ...data }
	// 	var sendData = {}
	// 	if (type === 'owner') {
	// 		dataCopy.data[index][e.target.name] = e.target.checked
	// 		sendData = {
	// 			login: dataCopy.data[index]['login'],
	// 			activated: e.target.checked,
	// 		}
	// 		await PUT('/admin/pos-activate', sendData, { loader: true })
	// 	}
	// 	if (type === 'director') {
	// 		dataCopy.data[index].directors[index2][e.target.name] = e.target.checked
	// 		sendData = {
	// 			login: dataCopy.data[index].directors[index2]['login'],
	// 			activated: e.target.checked,
	// 		}
	// 		await PUT('/admin/pos-activate', sendData, { loader: true })
	// 	}
	// 	searchWithFilter()
	// }

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getUsers() {
		const response = await GET('/admin/users')
		if (response) {
			setUsers(response.data)
		}
	}

	useEffect(() => {
		getUsers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="vertical-center">
					<h4>
						{t('POSES')} [{data.total}]
					</h4>
				</div>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => setModalUpdate(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div className="vertical-center">
							<h5 className="d-flex justify-content-between">
								<b className="me-3">Активные:</b>
								<div>
									<span className="text-success">{formatMoney(data.total_active)}</span> |
									<span className="text-danger">{formatMoney(data.total_in_active)}</span>
								</div>
							</h5>
							<h5 className="d-flex justify-content-between">
								<b className="me-5">Сумма долга:</b> {formatMoney(data.total_balance)} сум
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th className="text-start">{t('LOGIN')}</th>
									<th>{t('POS')}</th>
									<th>{t('PHONE')}</th>
									<th className="text-center th-sortable">{t('TARIFF')}</th>
									<th className="text-center th-sortable">{t('BALANCE')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-start">{index + 1}. {item.login}</td>
										<td>{item.id}. {item.name}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td className="text-center">{formatMoney(item.tariff)}</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										{/* <td className="text-center">
											<>
												<div className="form-check form-switch form-switch-lg" title={item.login}>
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => handleChangeActivated(e, index, 'owner')} />
												</div>
												<>
													{item?.directors?.map((director, index2) => (
														<div key={index2}>
															<div className="form-check form-switch form-switch-lg" title={director.login}>
																<input type="checkbox" className="form-check-input"
																	name="activated"
																	checked={director.activated}
																	onChange={(e) => handleChangeActivated(e, index, 'director', index2)} />
															</div>
														</div>
													))
													}
												</>
											</>
										</td> */}
										<td>
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button"
													onClick={() => openModalUpdate(item)}>
													<i className="uil-edit-alt"></i>
												</div>

												{/* <div className="table-action-button table-action-success-button ms-2"
													onClick={() => openModalPayment(item)}>
													<i className="uil-money-withdraw"></i>
												</div>

												<div className="table-action-button table-action-primary-button ms-2"
													onClick={() => openModalInfo(item)}>
													<i className="uil-info-circle"></i>
												</div>
												<div className="table-action-button table-action-primary-button ms-2"
													onClick={() => openModalCashiers(item)}>
													<i className="uil-info-circle"></i>
												</div> */}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL UPDATE*/}
			<Modal show={modalUpdate} animation={false} centered
				dialogClassName="update-modal-width"
				onHide={() => setModalUpdate(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>Создать</span>
							:
							<span>Редактировать</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>Логин</label>
							<Select
								options={users}
								value={users.find(option => option.login === selectedItem.login)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'login': option.login })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.login}
								getOptionValue={(option) => option.login}
							/>
						</div>
						<div className="form-group">
							<label>Название</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Телефон</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="text" className="form-control" name="phone" value={selectedItem.phone}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>Адрес</label>
							<input type="text" className="form-control" name="address" value={selectedItem.address}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'address': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Баланс</label>
							<input type="text" className="form-control" name="balance"
								disabled
								value={selectedItem.balance}
								onChange={() => { }} />
						</div>
						<div className="form-group">
							<label>Тариф</label>
							<input type="text" className="form-control" name="tariff" value={selectedItem.tariff}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'tariff': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setModalUpdate(false)}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL UPDATE*/}

			{/* MODAL PAYMENT*/}
			<Modal show={modalPayment} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalPayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>Оплата</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => payment(e)} autoComplete="off">
						<div className="form-group">
							<label>Сумма</label>
							<input type="text" className="form-control" name="amount"
								value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>Примечание</label>
							<input type="text" className="form-control" name="note" value={selectedItem.note}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => setModalPayment(false)}>
								{t('CANCEL')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT*/}

			{/* MODAL INFO*/}
			{/* <Modal show={modalInfo} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalInfo(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						История
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th className="text-nowrap">{t('DATE')}</th>
									<th className="text-nowrap text-center">{t('AMOUNT')}</th>
									<th className="text-nowrap text-center">{t('NOTE')}</th>
								</tr>
							</thead>
							<tbody>
								{payments?.map((item, index) => (
									<tr key={index}>
										<td>{formatDate(item.created_at)}</td>
										<td className="text-center">{formatMoney(item.amount)}</td>
										<td className="text-center">{item.note}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal> */}
			{/* MODAL INFO*/}

			{/* MODAL CASHIERS*/}
			{/* <Modal show={modalCashiers} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalCashiers(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						Кассиры
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th className="text-nowrap text-center">{t('LOGIN')}</th>
									<th className="text-nowrap text-center">{t('NAME')}</th>
								</tr>
							</thead>
							<tbody>
								{
									cashiers?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{item.login}</td>
											<td className="text-center">{item.first_name + ' ' + item.last_name}</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal> */}
			{/* MODAL CASHIERS*/}
		</>
	)
}

export default Index