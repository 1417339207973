import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate, formatMoney, numberToWords } from 'helpers/helpers'

function ReportPrint({ dailyReport, printRef, activeTab, selectedOperators, fontSize }) {
	const { t } = useTranslation()

	return (
		<>
			<div ref={printRef}>
				{activeTab === 1 ?
					<div>
						<div className="container-fluid">
							<div className="row mb-3">
								<div className="col-md-3 offset-1">
									{selectedOperators?.accepted?.name &&
										<div>
											{t('shift_supervisor')}: {selectedOperators?.accepted?.name}
										</div>
									}
								</div>
								<div className="col-md-4 fz-20 text-center">
									{t('shift_report2')}
									<div>{dailyReport?.pos_name}</div>
									<div>
										{formatDate(dailyReport?.shift?.opened_at)}
									</div>
								</div>
								<div className="col-md-3">
									{dailyReport?.data?.filter(el => el.total)?.map((item, index) => (
										<div className="d-flex justify-content-between" key={index}>
											<div className="me-5">
												{item.name + ' '}
												({item.storage_name})
											</div>
											<div>
												{formatMoney(item.total_sold * item.price)}
											</div>
										</div>
									))
									}
									<hr />
									<div className="d-flex justify-content-between fw-bold">
										<div className="me-5">
											{t('TOTAL')}
										</div>
										<div>
											{formatMoney(dailyReport.total_sold)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="px-5">
							<table className="table print-table" style={{ fontSize: fontSize + 'px' }}>
								<thead>
									<tr>
										<th style={{ width: '7%' }} rowSpan="2" className="after-102">{t('FUEL_TYPE')}</th>
										<th style={{ width: '7%' }} rowSpan="2">{t('balance_start_of_shift')}</th>
										<th style={{ width: '7%' }} rowSpan="2" className="after-101">{t('admission_per_shift')}</th>
										<th style={{ width: '20%' }} colSpan="4" className="after-101" scope="colgroup">{t('indication_of_mechanisms')}</th>
										<th style={{ width: '30%' }} colSpan="7" scope="colgroup">{t('leftovers_at_end_of_shift')}</th>
										<th style={{ width: '6%' }} colSpan="1" scope="colgroup">{t('result')}</th>
										<th style={{ width: '3%' }} rowSpan="2">{t('shortage')}</th>
										<th style={{ width: '3%' }} rowSpan="2">{t('permitted_rate')}</th> 
										<th style={{ width: '3%' }} rowSpan="2">{t('error_pump')}</th>
										<th style={{ width: '1%' }} className="border-none" rowSpan="2"></th>
									</tr>
									<tr> 
										{/* Показание механизмов */}
										<th style={{ width: '2%' }}>№ <br />{t('FUEL_DISPENSER')}</th>
										<th>{t('the_end_of_shift')}</th>
										<th>{t('the_beginning_of_shift')}</th>
										<th className="after-101">{t('sold_per_shift')}</th>
										{/* Показание механизмов */}

										{/* Остатки на конец смены */}
										<th className="after-101" style={{ width: '3%' }}>{t('STORAGE_TANK')}</th>
										<th className="after-101">{t('general_measurement')} <br /> (мм)</th>
										<th className="after-101">{t('water_metering')} <br /> (мм)</th>
										<th className="after-101">{t('measurement_oil')} <br /> (мм)</th>
										<th className="after-101">{t('in_tank')}</th>
										<th style={{ width: '3%' }}>{t('in_pipeline')}</th>
										<th>{t('balance_at_end_of_shift')}</th>
										{/* Остатки на конец смены */}

										{/* Результат */}
										<th>{t('balance_of_accounting_department')}</th>
										{/* Результат */}
									</tr>
								</thead>
								<tbody>
									<tr className="tr-center">
										<td>1</td>
										<td>2</td>
										<td>3</td>
										<td>4</td>
										<td>5</td>
										<td>6</td>
										<td>7</td>
										<td>8</td>
										<td>9</td>
										<td>10</td>
										<td>11</td>
										<td>12</td>
										<td>13</td>
										<td>14</td>
										<td>15</td>
										<td>16</td>
										<td>17</td>
										<td>18</td>
									</tr>
									{dailyReport?.data?.map((item, index) => (
										<React.Fragment key={index}>
											{
												item?.dispensers?.map((item2, index2) => (
													<tr className="tr-border-none" key={index2}>
														<td className="text-center">
															{index2 === 0 ? item?.name : ''}
															{index2 === 1 ? formatMoney(item?.price) + '  сум' : ''}
														</td>
														<td className="text-end">{index2 === 0 ? formatMoney(item.prev_balance) : ''}</td>
														<td className="text-end">{index2 === 0 ? formatMoney(item.total_fuel_in) : ''}</td>
														<td className="text-center">{item2.name}</td>
														<td className="text-end">{formatMoney(item2.final)}</td>
														<td className="text-end">{formatMoney(item2.beginning)}</td>
														<td className="text-end">{formatMoney(item2.sold)}</td>
														<td className="text-center">{index2 === 0 ? item.storage_name ?? 0 : ''}</td>
														<td className="text-end">{index2 === 0 ? item.fuel_metering : ''}</td>
														<td className="text-end">{index2 === 0 ? item.water_metering : ''}</td>
														<td className="text-end">{index2 === 0 ? item.general_metering : ''}</td>
														<td className="text-end">{index2 === 0 ? item.in_storage_tank : ''}</td>
														<td className="text-end">{index2 === 0 ? item.in_pipiline : ''}</td>
														<td className="text-end">{index2 === 0 ? item.total_actual : ''}</td>
														<td className="text-end">{index2 === 0 ? item.total_by_accounting : ''}</td>
														<td className="text-end">{index2 === 0 && (item.lack > 0 && '-') + Math.abs(item.lack)}</td>
														<td className="text-end">{index2 === 0 ? item.current_fuel_inaccuracy : ''}</td>
														<td className="text-end">{item2.innacuracy}</td>
														<td className="border-none"></td>
													</tr>
												))
											}
											{(!item?.dispensers?.length && !item?.total) &&
												<tr className="tr-border-none">
													<td className="text-center">{item.name}</td>
													<td className="text-end">{formatMoney(item.prev_balance)}</td>
													<td className="text-end">{formatMoney(item.total_fuel_in)}</td>
													<td className="text-center">{ }</td>
													<td className="text-center">{ }</td>
													<td className="text-center">{ }</td>
													<td className="text-center">{ }</td>
													<td className="text-center">{item.storage_name}</td>
													<td className="text-end">{item.fuel_metering}</td>
													<td className="text-end">{item.water_metering}</td>
													<td className="text-end">{item.general_metering}</td>
													<td className="text-end">{item.in_storage_tank}</td>
													<td className="text-end">{item.in_pipiline ?? 0}</td>
													<td className="text-end">{item.total_actual}</td>
													<td className="text-end">{item.total_by_accounting}</td>
													<td className="text-end">{item.lack > 0 && '-'} {Math.abs(item.lack)}</td>
													<td className="text-end">{item.current_fuel_inaccuracy}</td>
													<td ></td>
													<td className="border-none"></td>
												</tr>
											}
											{item?.total &&
												<tr className="tr-bold">
													<td className="text-center">{t('TOTAL') + ': ' + item.name}</td>
													<td className="text-end">{formatMoney(item.prev_balance)}</td>
													<td className="text-end">{formatMoney(item.total_fuel_in)}</td>
													<td className="text-center">{ }</td>
													<td className="text-center">{ }</td>
													<td className="text-center">{ }</td>
													<td className="text-end">{item.total_sold}</td>
													<td className="text-center">{ }</td>
													<td className="text-end">{item.fuel_metering}</td>
													<td className="text-end">{item.water_metering}</td>
													<td className="text-end">{item.general_metering}</td>
													<td className="text-end">{item.in_storage_tank}</td>
													<td className="text-end">{item.in_pipiline}</td>
													<td className="text-end">{item.total_actual}</td>
													<td className="text-end">{item.total_by_accounting}</td>
													<td className="text-end">{item.lack > 0 && '-'} {Math.abs(item.lack)}</td>
													<td className="text-end">{item.total_fuel_inaccuracy ?? 0}</td>
													<td className="text-end">{item.total_current_dispenser_inaccuracy}</td>
													<td className="border-none">{item.total_dispenser_inaccuracy ?? 0}</td>
												</tr>
											}
										</React.Fragment>
									))
									}
								</tbody>
							</table>
						</div>
					</div>
					:
					<div className="container">
						<div>
							<div className="row mb-3">
								<div className="col-md-4"></div>
								<div className="col-md-4 fz-20 text-center">
									<div>{dailyReport?.pos_name}</div>
									<div>
										{formatDate(dailyReport?.shift?.opened_at)}
									</div>
								</div>
							</div>
						</div>
						<table className="table print-table">
							<thead>
								<tr>
									<th rowSpan="2" className="after-102">{t('FUEL_TYPE')}</th>
									<th rowSpan="2" className="after-101">{t('OIL_STORAGE')}</th>
									<th rowSpan="2">{t('tnn')}</th>
									<th colSpan="2" scope="colgroup">{t('QUANTITY')}</th>
									<th rowSpan="2">{t('weight')} (лт.)</th>
								</tr>
								<tr>
									<th>{t('weight')}  (кг.)</th>
									<th>{t('density')}</th>
								</tr>
							</thead>
							<tbody>
								{dailyReport?.data?.map((item, index) => (
									!item.total &&
									<React.Fragment key={index} >
										<tr>
											<td className="text-center">
												{item.name}
											</td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										{
											item?.fuelIn?.map((item2, index2) => (
												<tr key={index2}>
													<td className="text-center">{ }</td>
													<td className="text-center">{item2.oil_storage_name}</td>
													<td className="text-center">{item2.tin}</td>
													<td className="text-center">{formatMoney(item2.mass_kg)}</td>
													<td className="text-center">{item2.density}</td>
													<td className="text-center">{formatMoney(item2.mass_liter)}</td>
												</tr>
											))
										}
									</React.Fragment>
								))
								}
							</tbody>
						</table>
						<div className="container-fluid">
							<div className="d-flex">
								<div className="print_card w-50 me-3">
									<b>Наличные</b>
									<div className="d-flex justify-content-between">
										Бош колдиг
										<div>
											{formatMoney(dailyReport.general.prev_balance)}
										</div>
									</div>
									<div className="d-flex justify-content-between">
										Смена тушуми
										<div>
											{formatMoney(Number(dailyReport.general.cash))}
										</div>
									</div>
									<div className="d-flex justify-content-between">
										Банкга топширилди
										<div>
											{formatMoney(dailyReport?.general?.deposited_to_bank)}
										</div>
									</div>
									{dailyReport?.payments?.map((item, index) => (
										item.payment_type_id !== 1 &&
										<div className="d-flex justify-content-between" key={index}>
											{item.payment_name}
											<div>
												{formatMoney(Number(item.total))}
											</div>
										</div>
									))
									}
								</div>
								<div className="print_card w-100 me-3">
									<b>Платежи</b>
									<table className="table mb-0">
										<thead>
											<tr>
												<th className="text-start">{t('FUEL')}</th>
												{dailyReport?.data?.filter(el => !el.total)[0]?.payments.map((item, index) => (
													<th className="p-0" key={index}>{item.payment_name}</th>
												))
												}
											</tr>
										</thead>
										<tbody>
											{dailyReport?.data?.filter(el => !el.total)?.map((item, index) => (
												item?.payments?.length > 0 &&
												<tr key={index}>
													<td className="text-start p-0">{item.name}</td>
													{item.payments?.map((item2, index2) => (
														<td key={index2}>
															{item2.amount}
														</td>
													))}
												</tr>
											))
											}
											<tr className="tr-bold">
												<td className="text-start p-0">Жами</td>
												{dailyReport.payments.map((item, index) => (
													<td key={index}>
														{formatMoney(Number(item.total))}
													</td>
												))
												}
											</tr>
											<tr className="tr-bold">
												<td className="text-start p-0">Налог</td>
												{dailyReport.payments.map((item, index) => (
													<td key={index}>
														{formatMoney(Number(item.total_tax))}
													</td>
												))
												}
											</tr>
										</tbody>
									</table>
								</div>
								<div className="print_card w-50">
									<b>{t('tax')}</b>
									<table className="table">
										<thead>
											<tr>
												<th className="text-start">{t('FUEL')}</th>
												<th>{t('tax')}</th>
												<th>{t('LITR')}</th>
												<th>{t('AMOUNT')}</th>
											</tr>
										</thead>
										<tbody>
											{dailyReport?.data?.filter(el => el.total)?.map((item, index) => (
												<tr key={index}>
													<td className="text-start p-0">{item.name}</td>
													<td>{item.tax}</td>
													<td>{formatMoney(item.total_sold)}</td>
													<td>{formatMoney(item.tax * item.total_sold)}</td>
												</tr>
											))
											}
											<tr>
												<td className="text-start p-0">Жами</td>
												<td></td>
												<td></td>
												<td>{formatMoney(dailyReport.total_tax)}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div className="d-flex mt-3">
								<div className="w-100 me-5">
									<div className="d-flex justify-content-between w-100">
										<b>Смена колдиги:</b>
										<b>
											{formatMoney(dailyReport.general.next_balance)}
										</b>
									</div>
									<div className="devider" />
									<div className="mb-3">
										{numberToWords(dailyReport.general.next_balance)}
									</div>
									<b className="d-flex justify-content-between">
										Хисобот ва сменани топширди:
										<div>
											{selectedOperators?.accepted?.name}
										</div>
									</b>
									<div className="devider" />
								</div>
								<div className="w-100">
									<b className="d-flex justify-content-between w-100">
										Олдинги сменадан кабул килинди:
										<div>
											{formatMoney(dailyReport.general.next_balance)}
										</div>
									</b>
									<div className="devider" />
									<div className="mb-3">
										{numberToWords(dailyReport.general.next_balance)}
									</div>
									<b className="d-flex justify-content-between">
										Кабул килди:
										<div>
											{selectedOperators?.passed?.name}
										</div>
									</b>
									<div className="devider" />
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</>
	)
}

export default ReportPrint