import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { formatDate, regexPhoneNumber, sortByNumber } from 'helpers/helpers'
import { DELETE, GET, POST, PUT } from 'helpers/api'
import DeleteModal from "pages/parts/DeleteModal"

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'fuel_id': '',
		'storage_tank_id': '',
		'quantity': 1,
	})

	const [data, setData] = useState([])

	const [showCreateModal, setShowCreateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.role = 'ROLE_CASHIER'
		selectedItemCopy.phone = '998' + selectedItemCopy.phone
		if (!selectedItemCopy.id) {
			await POST('/cashier', selectedItemCopy)
		} else {
			await PUT('/cashier', selectedItemCopy)
		}

		setSelectedItem({})
		setShowModal(true)
		setShowCreateModal(false)
		getData()
	}

	async function deleteItem() {
		var selectedItemCopy = { ...selectedItem }
		await DELETE('/cashier/' + selectedItemCopy.id, {})
		setSelectedItem({})
		setShowDeleteModal(false)
		getData()
	}

	function openModal(item) {
		var selectedItemCopy = { ...item }
		selectedItemCopy.phone = selectedItemCopy.phone?.substring(3, 12)
		setSelectedItem({ ...selectedItemCopy })
		setShowModal(false)
		setShowCreateModal(true)
	}

	function openDeleteModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem({ id: selectedItemCopy.id })
		setShowDeleteModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'name': '',
			'fuel_id': '',
			'storage_tank_id': '',
			'quantity': 1,
		})
		setShowModal(true)
		setShowCreateModal(false)
	}

	async function getData() {
		const response = await GET('/cashier', {}, { loader: true })
		if (response) {
			setData(sortByNumber(response))
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('CASHIERS')}</h4>
						<button className="btn-rounded btn btn-primary me-2" onClick={() => openModal()}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th>{t('LOGIN')}</th>
											<th>{t('NAME')}</th>
											<th className="text-center">{t('PHONE')}</th>
											<th className="text-center">{t('PIN_CODE')}</th>
											<th className="text-center">{t('DATE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<tr key={index}>
												<td>{index + 1}. {item.login}</td>
												<td>{item.name}</td>
												<td className="text-center">{regexPhoneNumber(item.phone)}</td>
												<td className="text-center">{item.pin_code}</td>
												<td className="text-center">{formatDate(item.created_at)}</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button me-2" onClick={() => openModal(item)}>
														<i className="uil-edit-alt"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => openDeleteModal(item)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* MODAL */}
			<Modal show={showCreateModal} animation={false} centered dialogClassName="update-modal-width" onHide={() => closeModal()} size="md">
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('CREATE')}</span>
							:
							<span>{t('EDIT')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<div className="form-group">
								<label>{t('LOGIN')}</label>
								<input type="text" className="form-control" name="login" value={selectedItem.login} disabled={selectedItem.id}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'login': e.target.value })} />
							</div>
							<div className="form-group">
								<label>{t('NAME')}</label>
								<input type="text" className="form-control" name="name" value={selectedItem.name}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
							</div>
							<div className="form-group">
								<label>{t('PHONE')}</label>
								<div className="input-group">
									<span className="input-group-text">998</span>
									<input type="text" className="form-control" name="phone" value={selectedItem.phone}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
								</div>
							</div>
							<div className="form-group">
								<label>{t('PIN_CODE')}</label>
								<input type="text" className="form-control" name="pin_code" value={selectedItem.pin_code}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value, 'pin_code': e.target.value })} />
							</div>

							<div className="d-flex w-100 mt-3">
								<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
								<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={showDeleteModal}
				setModalDelete={setShowDeleteModal}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index