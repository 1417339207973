export function SET_REPORT_DATA(payload) {
	return {
		type: 'SET_REPORT_DATA',
		payload: payload,
	};
}

export function SET_CALIBRATION(payload) {
	return {
		type: 'SET_CALIBRATION',
		payload: payload,
	};
}

export function UPDATE_SHIFT(payload) {
	return {
		type: 'UPDATE_SHIFT',
		payload: payload,
	};
}