import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

import { Modal } from 'react-bootstrap'

import { formatDateWithTime } from 'helpers/helpers'
import { GET } from 'helpers/api'

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const [data, setData] = useState([])

	async function getData() {
		const response = await GET('/log', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('action_history')}</h4>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-start">{t('DATE')}</th>
											<th className="text-start">{t('LOGIN')}</th>
											<th className="text-start">{t('TYPE')}</th>
											<th className="text-start">{t('ACTION')}</th>
											<th className="text-start">{t('ID')}</th>
											<th className="text-start">{t('COMMENT')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{formatDateWithTime(item.created_at)}</td>
												<td className="text-start">{item.login}</td>
												<td className="text-start">{item.name}</td>
												<td className="text-start">
													{item.type === '1' && t('CREATE')}
													{item.type === '2' && t('EDIT')}
													{item.type === '3' && t('DELETE')}
												</td>
												<td className="text-start">{item.document_id}</td>
												<td className="text-start">{item.comment}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Index