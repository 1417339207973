import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { formatDate } from 'helpers/helpers'
import { DELETE, GET, POST, PUT } from 'helpers/api'
import DeleteModal from "pages/parts/DeleteModal"

function Index({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'car_number': '',
		'mark': '',
		'capacity': '',
		'carpool_id': '',
	})
	const [carpools, setCarpool] = useState([])
	const [data, setData] = useState([])
	const [showCreateModal, setShowCreateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		if (!selectedItemCopy.id) {
			await POST('/car', selectedItemCopy)
		} else {
			await PUT('/car', selectedItemCopy)
		}

		setSelectedItem({})
		setShowModal(true)
		setShowCreateModal(false)
		getData()
	}

	async function deleteItem() {
		var selectedItemCopy = { ...selectedItem }
		await DELETE('/car/' + selectedItemCopy.id, {})
		setSelectedItem({})
		setShowDeleteModal(false)
		getData()
	}

	function openModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem(selectedItemCopy)
		setShowModal(false)
		setShowCreateModal(true)
	}

	function openDeleteModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem({ id: selectedItemCopy.id })
		setShowDeleteModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'name': '',
			'car_number': '',
			'mark': '',
			'capacity': '',
			'carpool_id': '',
		})
		setShowModal(true)
		setShowCreateModal(false)
	}

	async function getData() {
		const response = await GET('/car', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	async function getCarpools() {
		const response = await GET('/carpool')
		if (response) {
			setCarpool(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
			getCarpools()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width"  onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('CAR')}</h4>
						<button className="btn-rounded btn btn-primary me-2" onClick={() => openModal()}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table table-striped mb-0">
									<thead>
										<tr>
											<th className="text-center">{t('FIO')}</th>
											<th className="text-center">{t('CAR_NUMBER')}</th>
											<th className="text-center">{t('MARK')}</th>
											<th className="text-center">{t('CAPACITY')}</th>
											<th className="text-center">{t('CARPOOL')}</th>
											<th className="text-center">{t('DATE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{data?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{item.name}</td>
												<td className="text-center">{item.car_number}</td>
												<td className="text-center">{item.mark}</td>
												<td className="text-center">{item.capacity}</td>
												<td className="text-center">{item.carpool_name}</td>
												<td className="text-center">{formatDate(item.created_at)}</td>
												<td className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button me-2" onClick={() => openModal(item)}>
														<i className="uil-edit-alt"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => openDeleteModal(item)}>
														<i className="uil-trash-alt"></i>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* MODAL */}
			<Modal show={showCreateModal} animation={false} centered dialogClassName="update-modal-width"  onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('CREATE')}</span>
							:
							<span>{t('EDIT')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('FIO')}</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('CAR_NUMBER')}</label>
							<input type="text" className="form-control" name="car_number" value={selectedItem.car_number}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'car_number': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('MARK')}</label>
							<input type="text" className="form-control" name="mark" value={selectedItem.mark}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'mark': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('CAPACITY')}</label>
							<input type="text" className="form-control" name="capacity" value={selectedItem.capacity}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'capacity': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('CARPOOL')}</label>
							<Select
								options={carpools}
								value={carpools.find(option => option.id === selectedItem.carpool_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'carpool_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={showDeleteModal}
				setModalDelete={setShowDeleteModal}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index