import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GUESTPOST } from 'helpers/api'

function ForgotPassword() {
	const { t } = useTranslation();
	const navigate = useNavigate()

	const [user, setUser] = useState({ "login": "", "oldPassword": "", "newPassword": "" })

	async function changePassword() {
		const response = await GUESTPOST('/change-password', user, { loader: true })
		if (response) {
			toast.success(t('DATA_SUCCESFULLY_CHANGED'))
			navigate("/auth/login")
		}
	}

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('RECOVER_YOUR_PASSWORD')}</h3>
									</div>
									<input className="login-input"
										autoComplete="off"
										name="login"
										value={user.login}
										placeholder={t('LOGIN')}
										onChange={(e) => setUser({ ...user, 'login': e.target.value })} />
									<input className="login-input"
										autoComplete="off"
										name="oldPassword"
										value={user.oldPassword}
										placeholder={t('OLD_PASSWORD')}
										onChange={(e) => setUser({ ...user, 'oldPassword': e.target.value })} />

									<input className="login-input"
										autoComplete="off"
										name="newPassword"
										value={user.newPassword}
										placeholder={t('NEW_PASSWORD')}
										onChange={(e) => setUser({ ...user, 'newPassword': e.target.value })} />

									<div className="text-center">
										<button type="button" className="login-button"
											onClick={changePassword}>
											{t('CHANGE_PASSWORD')}
										</button>
									</div>

									<div className="mt-2 text-center">
										<Link to="/auth/login" className="fw-medium text-white">{t('LOGGING_IN')}</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword
