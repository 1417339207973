import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { useSelector } from 'react-redux'

import ReportPrint from 'pages/components/ShiftReport'
import Select from 'react-select'

import { GET } from 'helpers/api'
import { formatDate, sortByNumber } from 'helpers/helpers'
import useDidMountEffect from 'pages/parts/useDidMountEffect'

function DailyReport() {
	const { t } = useTranslation()

	const printRef = useRef(null)

	const reduxAccount = useSelector(state => state.account)

	const [activeTab, setActiveTab] = useState(1)

	const [filterData, setFilterData] = useState({
		pos_id: null,
		shift_id: null,
	})
	const [dailyReport, setDailyReport] = useState({
		general: {},
		data: [],
		shift: {},
	})

	const [poses, setPoses] = useState([])
	const [shifts, setShifts] = useState([])

	const handlePrint = useReactToPrint({
		content: () => printRef.current
	});

	async function getDailyReport() {
		var copyData = { ...filterData }
		const response = await GET('/daily-report', copyData)
		if (response != null) {
			response.total_sold = 0;
			response.total_tax = 0;
			response.payments = [];
			for (let i = 0; i < response.data.length; i++) {
				var item = response.data[i]
				if (response.data[i]?.dispensers?.length > 0) {
					response.data[i].dispensers = sortByNumber(response.data[i]?.dispensers)
				}
				if (item.total) {
					response.total_tax += Number(item?.total_sold) * Number(item?.tax)
					response.total_sold += item?.total_sold * item?.price
				} else {
					for (let j = 0; j < item?.payments?.length; j++) {
						if (i === 0) {
							item.payments[j].total = Number(item?.payments[j].amount) * Number(item?.price)
							item.payments[j].total_tax = Number(item?.payments[j].amount) * Number(item?.tax)
							response.payments.push(item?.payments[j])
						} else {
							response.payments[j].total += Number(item?.payments[j].amount) * Number(item?.price)
							response.payments[j].total_tax += Number(item?.payments[j].amount) * Number(item?.tax)
						}
					}
				}
			}
			setDailyReport(response)
		}
	}

	async function getPoses() {
		const response = await GET('/pos-helper', { login: reduxAccount.parent },)
		if (response) {
			setPoses(response)
			setFilterData({ ...filterData, pos_id: response[0]?.id })
		}
	}

	async function getShifts() {
		const response = await GET('/shift-helper', { only_closed: true, pos_id: filterData.pos_id })
		if (response) {
			setShifts(response)
			setFilterData({ ...filterData, shift_id: response[0]?.id })
		}
	}

	useEffect(() => {
		getPoses()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getShifts()
	}, [filterData.pos_id]);

	useDidMountEffect(() => {
		getDailyReport()
	}, [filterData.shift_id]);

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex align-items-center gap-3">
					<h4>
						{t('SHIFT_REPORT')}
					</h4>

					<div style={{ width: '250px' }}>
						<Select
							options={poses}
							value={poses.find(option => option.id === filterData.pos_id)}
							onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>

					<div style={{ width: '300px' }}>
						<Select
							options={shifts}
							value={shifts.find(option => option.id === filterData.shift_id)}
							onChange={(option) => setFilterData({ ...filterData, 'shift_id': option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => formatDate(option.opened_at)}
							getOptionValue={(option) => option.id}
						/>
					</div>

					<div className="form-check">
						<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
							checked={activeTab === 1} onChange={() => setActiveTab(1)} />
						<label className="form-check-label" htmlFor="flexRadioDefault1">
							Передняя сторона
						</label>
					</div>
					<div className="form-check">
						<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
							checked={activeTab === 2} onChange={() => setActiveTab(2)} />
						<label className="form-check-label" htmlFor="flexRadioDefault2">
							Задняя сторона
						</label>
					</div>
				</div>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => handlePrint()}>
						<i className="uil uil-print me-2"></i>
						{t('PRINT')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					{dailyReport?.data?.length > 0 &&
						<div className={"table-responsive " + (dailyReport?.data?.length ? '' : 'd-none')}>
							<ReportPrint dailyReport={dailyReport} activeTab={activeTab} printRef={printRef} selectedOperators={{}} />
						</div>
					}
				</div>
			</div>
		</>
	)
}

export default DailyReport